/* the stand
*/

.the-stand {
  transition: ease all .3s;
  cursor: pointer;
  width: 160px;
  height: 200px;
  text-align: left;
  padding: 5px;
}

.THESTAND-background {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Existing styles */
  width: 160px;
  height: 188px;
  background-color: rgba(0, 0, 0, 0.273);
  border-radius: 26px;  
  backdrop-filter: blur(3px);
}




.thestand-img {
  width: 100%;
  height: 100%;
/*  background-image: url("./THESTAND.png");*/
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 22px;
  position: relative;
  right: -6px;
  top: 5px;
}


.THESTAND-title {
  position: relative;
  top: 0px;
  padding-top: 7px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  padding-left: 5px;
}
  
.enter-thestand-btn {
  border-radius: 11px;
  background: linear-gradient(180deg,rgba(0,0,0,.22) 0%,rgba(0,0,0,.22) 100%);
  border: 1px solid rgba(131, 251, 225, 0.865);
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.43);
  border-radius: 10px;
  cursor: pointer;
  width: 90px;
  padding: 5px;
  font-family: ShortStack;
}

.enter-thestand-btn:hover {
  cursor: pointer;
  transition: ease-in-out all .4s;
  box-shadow: 0px 0px 10px #498e99;
}

.shopping-district {
  position: absolute;
  top:655px;
  left: 52%;
  transition: ease all .3s;
  cursor: pointer;
  width: 160px;
  height: 200px;
  text-align: left;
  padding: 5px;
}

.SHOPPING-background {
  width: 160px;
  height: 188px;
  background-color: rgba(0, 0, 0, 0.273);
  align-items: center;
  border-radius: 26px;  
  backdrop-filter: blur(3px);
}



.shopping-img {
  width: 150px;
  height: 178px;
/*  background-image: url("./SHOPPING.png");*/
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 22px;
  position: relative;
  right: -6px;
  top: 5px;
}

  

.SHOPPING-title {
  position: relative;
  top: 0px;
  padding-top: 7px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  padding-left: 5px;
}
  
.enter-shopping-btn {
  position: relative;
  top:-10px;
  left: 3px;
  border-radius: 11px;
  background: linear-gradient(180deg,rgba(0,0,0,.22) 0%,rgba(0,0,0,.22) 100%);
  border: 1px solid rgba(131, 251, 225, 0.865);
  color: rgba(255, 255, 255, 0.7);  
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.43);
  border-radius: 10px;
  cursor: pointer;
  width: 90px;
  padding: 5px;
  font-family: ShortStack;
}

.enter-shopping-btn:hover {
  cursor: pointer;
  transition: ease-in-out all .4s;
  box-shadow: 0px 0px 10px #498e99;
}