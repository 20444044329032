.nickname-input {
  position: absolute;
  width: 250px;
  height: 60px;
  z-index: 100;
  right: 100px;
  top: 70px;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(194, 255, 203, 0.53);
  border-radius: 14px;
  font-size: 20px;
  padding-left: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  caret-color: #B1F2B7;
  transition: ease-in-out all 0.3s;
}

.nickname-input:focus,
.nickname-input:active {
  border: 1.5px solid #b1f2b7d7;
  outline: none;
}

.nickname-input::placeholder {
  color: #B1F2B7;
}

.nickname-input.wrong {
  border-color: #FF8A8A;
}

.nickname-input.wrong:focus,
.nickname-input.wrong:active {
  border-color: #FF8A8A;
}

.nickname-input.wrong::placeholder {
  color: #FF8A8A;
}

.nickname-input.wrong::selection {
  background: #FF8A8A;
  color: #fff;
}