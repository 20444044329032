.loading-txt {
  font-size: 4.2rem;
  letter-spacing: 1rem;
  background: -webkit-linear-gradient(0deg, rgb(146, 204, 236, 0.7) 0%, rgba(148,130,244,0.7) 49%, rgba(246,141,141,0.7) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  top: -20px;
  animation-name: loading-animation;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}


.loading-txt-plain {
  font-size: 2.2rem;
  letter-spacing: 0.4rem;
  background: bisque;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  top: -30px;
  animation-name: loading-animation;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

    text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

}



@keyframes loading-animation {
    0%{
      filter: blur(12px);
      opacity: 0;
    }
    100%{
      filter: blur(0);
      opacity: 1;
    }
  }






  .loading-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }



  .loading-spinner div {
    transform-origin: 40px 40px;
    animation: loading-spinner 1.2s linear infinite;
  }


  .loading-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20px;
   
    background: -webkit-linear-gradient(180deg, rgb(146, 204, 236, 0.7) 0%, rgba(148,130,244,0.7) 49%, rgba(246,141,141,0.7) 80%);
    background-size: 20%; 
  }



  .loading-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;

    background: black;


  }
  .loading-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
    background: black;
  }
  .loading-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
    background: black;
  }
  .loading-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
    background: black;
  }
  .loading-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
    background: black;
  }
  .loading-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
    background: black;
  }
  .loading-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
    background: black;
  }
  .loading-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
    background: black;
  }
  .loading-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
    background: black;
  }
  .loading-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
    background: black;
  }
  .loading-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
    background: black;
  }
  .loading-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: black;
  }


  @keyframes loading-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
















.loading-spinner-plain {
    display: inline-block;
    position: relative;
    width: 90px;
    height: 60px;
    top: -10px;
  }



  .loading-spinner-plain div {
    transform-origin: 40px 30px;
    animation: loading-spinner 1.2s linear infinite;
  }


  .loading-spinner-plain div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 4px;
    height: 12px;
    border-radius: 20px;
    
    background: bisque;
    background-size: 20%; 

      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

  }



  .loading-spinner-plain div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;

    background: black;


  }
  .loading-spinner-plain div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
    background: black;
  }
  .loading-spinner-plain div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: black;
  }

















.loading-txt-small {
  font-size: 2.2rem;
  letter-spacing: 0.8rem;
  background: -webkit-linear-gradient(0deg, rgb(146, 204, 236, 0.7) 0%, rgba(148,130,244,0.7) 49%, rgba(246,141,141,0.7) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  top: -20px;
  animation-name: loading-animation;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}


.loading-txt-plain-small {
  font-size: 2.2rem;
  letter-spacing: 0.4rem;
  background: bisque;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  top: -30px;
  animation-name: loading-animation;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

    text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

}



@keyframes loading-animation {
    0%{
      filter: blur(12px);
      opacity: 0;
    }
    100%{
      filter: blur(0);
      opacity: 1;
    }
  }






  .loading-spinner-small {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 90px;
  }



  .loading-spinner-small div {
    transform-origin: 40px 25px;
    animation: loading-spinner 1.2s linear infinite;
  }


  .loading-spinner-small div:after {
    content: " ";
    display: block;
    position: absolute;
    top: -px;
    left: 37px;
    width: 4px;
    height: 12px;
    border-radius: 20px;
   
    background: -webkit-linear-gradient(180deg, rgb(146, 204, 236, 0.7) 0%, rgba(148,130,244,0.7) 49%, rgba(246,141,141,0.7) 80%);
    background-size: 20%; 
  }



  .loading-spinner-small div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;

    background: black;


  }
  .loading-spinner-small div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
    background: black;
  }
  .loading-spinner-small div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
    background: black;
  }
  .loading-spinner-small div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
    background: black;
  }
  .loading-spinner-small div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
    background: black;
  }
  .loading-spinner-small div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
    background: black;
  }
  .loading-spinner-small div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
    background: black;
  }
  .loading-spinner-small div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
    background: black;
  }
  .loading-spinner-small div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
    background: black;
  }
  .loading-spinner-small div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
    background: black;
  }
  .loading-spinner-small div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
    background: black;
  }
  .loading-spinner-small div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: black;
  }


  @keyframes loading-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
















.loading-spinner-plain-small {
    display: inline-block;
    position: relative;
    width: 90px;
    height: 60px;
    top: -10px;
  }



  .loading-spinner-plain-small div {
    transform-origin: 40px 30px;
    animation: loading-spinner 1.2s linear infinite;
  }


  .loading-spinner-plain-small div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 4px;
    height: 12px;
    border-radius: 20px;
    
    background: bisque;
    background-size: 20%; 

      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

  }



  .loading-spinner-plain-small div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;

    background: black;


  }
  .loading-spinner-plain-small div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
    background: black;
  }
  .loading-spinner-plain-small div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: black;
  }






