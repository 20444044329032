.connect-button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-family: 'ShortStack';
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 25px;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  transition: ease-in-out all 0.3s;
  overflow: hidden; 
  box-shadow: 0 0 0 2px #fff; 
}

.connect-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.connect-button:focus,
.connect-button:active {
  outline: none;
}

.connect-button:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0%;
  background-color: rgba(76, 175, 80, 0.8);
  transition: width 3s ease-in-out;
  z-index: -1;
}

.connect-button:hover:before {
  background-color: rgba(76, 175, 80, 0.8);
}

.connect-button:active:before {
  width: 100%;
}

.connect-button:active:before {
  animation: fill 1.5s ease-in-out forwards;
}

@keyframes fill {
  0% {
    width: 0%;
    left: 0%;
  }
  100% {
    width: 100%;
    left: -2px;
  }
}
