 .sideBarToggled {

    margin-left:  10px;
    display: flex;
    justify-content: center;
    
    width: 280px;
    height: 45px;
    overflow-x: hidden;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), linear-gradient(180deg, rgba(51, 37, 70, 0.2) 0%, rgba(43, 54, 78, 0.2) 50%, rgba(29, 58, 61, 0.2) 100%);
    box-shadow: inset 0px -2px 12px rgba(0, 0, 0, 0.25);
    
    border-radius: 200px;

  
  }



 /*
 input {
    height: auto;
    width: 100%;
    appearance: none;
    background-color: #D8846F;
    
    opacity: 0;
    

    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;

    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    overflow: hidden;

    box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.11), inset -6px 4px 6px rgba(255, 156, 131, 0.71), inset -6px -5px 3px rgba(0, 0, 0, 0.2);
    
  }
*/




.buttoncenter {

    border-radius: 200px;



}


.buttonright {

    border-bottom-left-radius: 200px;
    border-top-left-radius: 200px;
    
}


.buttonleft {

    border-bottom-right-radius: 200px;
    border-top-right-radius: 200px;
    
}



  .sideBarToggle:hover {
    cursor: pointer;
  }

  
  .triStateToggle {
    display: flex;
    justify-content: center;
   
    border-radius: 50px;

    width: 18vw;
    min-width: 225px;
    height: 5vh;
    min-height: 40px;

    overflow-x: hidden;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), linear-gradient(180deg, rgba(51, 37, 70, 0.2) 0%, rgba(43, 54, 78, 0.2) 50%, rgba(29, 58, 61, 0.2) 100%);
    box-shadow: inset 0px -2px 12px rgba(0, 0, 0, 0.25);
  }
  #one {
    opacity: 1;
  }
  










::-webkit-scrollbar {
  display: none;  
}

html {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}




#img1 {
  
  width: 400px;
  left: 0px;
  top: 0px;
  
  
  z-index:0;
}


#img2 {
    width: 400px;
  left: 0px;
  top: 0px;
  
  z-index:2;
  
  opacity: 0;
  
-webkit-animation: flames 2s linear infinite alternate-reverse both 0.2s;
animation: flames 2s linear infinite alternate-reverse both 0.2s;
                
}






@-webkit-keyframes flames {
 0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(1px, -1px);
            transform: translate(1px, -1px);
  }
  40% {
    -webkit-transform: translate(1px, 1px);
            transform: translate(1px, 1px);
  }
  60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}










@keyframes flames {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(1px, -1px);
            transform: translate(1px, -1px);
  }
  40% {
    -webkit-transform: translate(1px, 1px);
            transform: translate(1px, 1px);
  }
  60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}


 @keyframes lightFlames {
        0%   { opacity: 0}
        100% { opacity: 1}
    }

    @keyframes hideFlames {
        0%   { opacity: 1}
        100% { opacity: 0}
    }

    @keyframes on {
        0%   { opacity: 0}
        100% { opacity: 0}
    }

    @keyframes off {
        0%   { opacity: 1}
        100% { opacity: 1}
    }





.flamesVisible {
  opacity: 0;
  animation: lightFlames 3s linear 0.5s 1 normal forwards ;
}

.flamesHidden {
  opacity: 1;
  animation: hideFlames 3s linear 0.5s 1 normal forwards ;
}






