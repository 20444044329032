
.leaderboard-img {

position: relative;

width: 40%;


min-width: 250px;

animation:  drop-down-leaderboard 0.5s cubic-bezier(0.232, 1.000, 0.322, 1.000) both;

-webkit-animation: drop-down-leaderboard 0.5s cubic-bezier(0.232, 1.000, 0.322, 1.000) both;

animation-delay: 0.6s;


z-index: 1000;

}


  @keyframes  drop-down-leaderboard {
    0% {
      -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.3);
              transform: translateY(-1000px) scaleY(2.5) scaleX(0.3);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes  drop-down-leaderboard {
        0% {
          -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.3);
                  transform: translateY(-1000px) scaleY(2.5) scaleX(0.3);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
                  filter: blur(40px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                  transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
                  transform-origin: 50% 50%;
          -webkit-filter: blur(0);
                  filter: blur(0);
          opacity: 1;
        }
      }













/*

.leaderboard {
    display: flex;
    flex-direction: column;
    width: 80%;
    position: relative;
    align-items: center;
    background: rgba(67, 89, 111, 1);
    box-shadow: 0px 0px 24px 3px rgba(191, 205, 202, 0.3);
    align-items: center;
    text-align: center;
    overflow: auto; 
    backdrop-filter: blur(28px);  

}




.leaderboard::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%);
        z-index: -10;
        opacity: 0.8;
        
}


.leaderboard::after {
        content: "";
        position: absolute; 
        height: 100%;
        width: 100%;
        background: rgba(67, 89, 111, 1);
        z-index: 1;
        filter: blur(20px);
}

*/


.leaderboard {
    display: flex;
    flex-direction: column;
   
  
    width: 100%;

    min-height: 75vh;

  
    position: relative;
   


    align-items: start;


    background: rgba(67, 89, 111, 1);


    border: 2px solid pink;
    border-image: linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%);
    border-image-slice: 1;

    box-shadow: 0px 0px 24px 3px rgba(191, 205, 202, 0.3) ;


    text-align: center;

    
    overflow: auto; 

   
    backdrop-filter: blur(28px);

    
    }






.clay-avatar {
  position: absolute;
  top: -10px;
  left: 100px;
  width: 70px;
  height: auto;
  opacity: 90%;   
}



.ranking-move-up {
  position: relative;
  width: 15%;
  top:-3px;
  left: 3px;
}


.ranking-move-down {
  position: relative;
  width: 15%;      
  top:-3px;
  left: 3px;
}


.ranking-move-same {
  position: relative;
  top:-3px;
  left: 3px;
  width: 15%;
}





.viewaddress-btn {
  background: rgba(217, 217, 217, 0.15);
  color:rgba(125, 249, 226, 0.7);
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  border: 18px;
  cursor: pointer;
}


.viewaddress-btn:hover {
  box-shadow:  3px 3px 3px rgba(44, 44, 44, 0.3);    
}








.load-more {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 20px;
    cursor: pointer;
    width: 150px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #15222f1e;
    overflow: hidden;
    border-radius: 7px;
    opacity: 0.9;
    color:bisque;
}



.load-more:before
    {
        content: '';
        position: absolute;
        width: 200px;
        height: 110%;
        opacity: 0.9;
        top:0px;
        background: linear-gradient(179.86deg, #7DF9E2 8.43%, #08696C 123.51%);
        color:bisque;
    
    }




    .load-more:after
    {
        content: '';
        position: absolute;
        inset: 2px;
        background: #344C5D;
        top: 2px;
        bottom: 2px;
        border-radius: 7px;
        color:bisque;
       
    }


.load-txt {
  color:bisque;
  z-index:3;
  letter-spacing: 0.1rem;
  font-size: 0.9rem;
  background: linear-gradient(179.86deg, #3ef7d5 8.43%, #08696C 123.51%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}


.load-more:hover {
    box-shadow:3px 3px 6px rgba(0, 0, 0, 0.17);
}



   

.blured-background {

 -webkit-backdrop-filter: blur(10px);

}



