.menuContainer {

width: 350px;
height: 400px;
background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);
box-sizing: border-box;
box-shadow: 0px 0px 28.3391px rgba(174, 245, 184, 0.25);
border-radius: 20px;
backdrop-filter: blur(6px);

align-items: center;
text-align: center;
display: flex;
flex-direction: column;
margin:0 auto;

justify-content: space-between;
animation: popout 1s ease-in-out;
-webkit-animation: popout 1s ease-in-out;

}


.menuTitle {

padding-top:30px;
letter-spacing: 0.01em;
color: rgba(255, 255, 255, 0.5);
text-shadow: 0px 0px 5.39792px rgba(255, 255, 255, 0.43);

}


.optionA {

  display: flex;
  flex-direction: column;
  width: 200px;
  height: 30px;

  background: rgba(175, 170, 170, 0.25);
  border-radius: 15px;
  border: none;
  outline: 2px solid rgba(0, 0, 0, 0.1);

  justify-content: space-between;
  margin-top: 30%;

  cursor: pointer;
  text-align: center;
  align-items: center;

 

}


.optionA:hover,  .optionA:focus {

  border: 2px solid rgba(183, 255, 193, 0.94);
  box-shadow: 0px 0px 5px rgba(183, 255, 193, 0.22);
  transition: ease-in-out all 0.3s; 


}


.optionA_selected {


  display: flex;
  flex-direction: column;
  width: 200px;
  height: 30px;

  background: rgba(175, 170, 170, 0.25);
  border-radius: 15px;
  border: none;
  outline: 2px solid rgba(0, 0, 0, 0.1);

  justify-content: space-between;
  margin-top: 30%;

  cursor: pointer;
  text-align: center;
  align-items: center;

  border: 2px solid rgba(183, 255, 193, 0.94);
  box-shadow: 0px 0px 5px rgba(183, 255, 193, 0.22);
  transition: ease-in-out all 0.3s; 


}



#buttontitle {

  font-family:  ShortStack;

  margin: auto;
  line-height: 1.25rem; 
  color: rgba(174, 245, 184, 0.5);
  letter-spacing: 0.02em;

  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.43);


}

.optionA:hover #buttontitle {
  color: rgba(174, 245, 184, 0.8);
  transition: ease-in-out all 0.3s; 
}

.optionA:hover #buttontitle {
  color: rgba(174, 245, 184, 0.8);
  transition: ease-in-out all 0.3s; 
}
.optionC:hover #buttontitle {
  color: rgba(174, 245, 184, 0.8);
  transition: ease-in-out all 0.3s; 
}
.optionD:hover #buttontitle {
  color: rgba(174, 245, 184, 0.8);
  transition: ease-in-out all 0.3s; 
}


@keyframes popout {
  from{transform:scale(0)}
  80%{transform:scale(1.1)}
  to{transform:scale(1)}
}
@-webkit-keyframes popout {
  from{-webkit-transform:scale(0)}
  80%{-webkit-transform:scale(1.1)}
  to{-webkit-transform:scale(1)}
}





.close {

background-color: transparent;
border: none;
font-family: ShortStack;
font-size: 1.7rem;
color: rgba(255, 255, 255, 0.3);
text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.22);
cursor: pointer;
position: absolute;
right: 15px;
top:10px;

}

.close:hover {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.8rem;
  transition: ease-in-out all 0.3s; 
}


