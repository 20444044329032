/*html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
 
    background-repeat: no-repeat;
  
    background-color: #848fa6;
    background-size: cover;
  
    height: 100vh;

   
}

canvas 
{

    touch-action: none;
}*/



.htmlUI iframe {


width: 722px;
height: 989px;

border-top-right-radius: 82px;
border-top-left-radius: 85px;

border-bottom-left-radius: 70px;

border-bottom-right-radius: 70px;
border: none;



background-color : transparent;

opacity: 0.6;

}



.ClayPayApp {


background-color: #090f0f;

width: 720px;
height: 986px;


align-items: center;

justify-content: center;
display: block;


opacity: 0.7;

border-top-right-radius: 82px;
border-top-left-radius: 85px;

border-bottom-left-radius: 70px;

border-bottom-right-radius: 70px;



transition: ease-in-out all 0.3s; 

box-shadow: inset -9px 4px 14px #68daf451, inset 5px 4px 14px #68daf44d, inset 0px -8px 29px #68daf43f;

/*cursor: url('./images/clay-cursor28.png'),auto;*/


transform:  scale(0.8);




user-select: none;
-moz-user-select: none;
-khtml-user-select: none;
-webkit-user-select: none;
-o-user-select: none;


user-drag: none;
-webkit-user-drag: none;
user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;

}

.imageLogo{
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


.cplogo {
    width: 25%;
    height: auto;
    object-fit: cover;
    object-position: bottom;
    position: relative;
    transform: translateX(15%);
    top: 5%;
    margin-left:auto;
    margin-right:auto;
    opacity: 0.7;
    filter: drop-shadow(0px 0px 2px #68daf47a) ;
}

.itemsTitle {


    color: #d3eef564;
    font-family: ClayPay;
    font-size: 2rem;
    text-shadow: 0px 0px 7px #68daf4a6;

    letter-spacing: 0.1rem;
    font-weight: 100;

    transition: ease-in-out all 0.3s; 

}


.continueShopping {


    color: #d3eef564;
    font-family: ClayPay;
    font-size: 1.8rem;
    text-shadow: 0px 0px 7px #68daf4a6;

    letter-spacing: 0.1rem;
    font-weight: 100;
    text-decoration: underline;
    text-decoration-color: #84e8fe41;

    transition: ease-in-out all 0.3s; 

    
    float: right;

    cursor: pointer;

    /*cursor: url('./images/clay-cursor28.png'),auto;*/
}


.buttonEdit {


    font-family: ClayPay;
    
      padding-left: 20px;
      padding-right: 20px;

      background-color: #426772;
    
         border: none;
      border-radius: 4px;
    
      outline: 3px solid rgba(0, 0, 0, 0.582) ;
    
      font-size: 2rem;
      color: #192025;
      letter-spacing: 0rem;
    

      transition: ease-in-out all 0.3s; 

      opacity: 0.9;

     
      margin-top: 310px;
      margin-right:-200px;
      margin-left:auto;
    
 
    float: right;

    /*cursor: url('./images/clay-cursor28.png'),auto;*/
    
    
    }
    
    .buttonEdit:hover, .buttonEdit:focus {
    
    color: #84E8FE;
    transition: ease-in-out all 0.3s;
    
    text-shadow: 0px 0px 3px #a9eaf9d5;
    
    outline: 1px solid rgba(0, 0, 0, 0.782) ;
    }

    .buttonEdit:focus {
      
        color: #84E8FE;
        outline:4px solid rgba(0, 0, 0, 0.782) ;
      }

      
      .buttonEdit:active{
        outline:4px solid rgba(0, 0, 0, 0.782) ;
      
        transition: none;
      }



.itemsPlaceholders {

display: flex;

align-items: center;
justify-content: center;

}



      .itemPlaceholderA {

      

        margin-top: 20px;
        margin-left:100px;
        margin-right:20px;

width:250px;
height:250px;

background-color: #132328;

align-items: center;
justify-content: center;

display: flex;

border-radius: 13px;

outline: 3px solid rgba(0, 0, 0, 0.2) ;

transition: ease-in-out all 0.3s;   

      }

      .itemPlaceholderA:hover {

        outline: 2px solid #84e8febd; 
        box-shadow: 0px 0px 2px rgba(134, 226, 208, 0.96);
        transition: ease-in-out all 0.3s; 

        /*cursor: url('./images/clay-cursor28.png'),auto;*/
      }


      .trousersA {

width: 40%;
height: auto;
filter:  drop-shadow(2px 3px 2px #0000006e);

      }

      


      .itemPlaceholderB {

      
width: 250px;
height: 250px;

background-color: #132328;

align-items: center;
justify-content: center;

display: flex;

border-radius: 13px;

outline: 3px solid rgba(0, 0, 0, 0.2) ;

transition: ease-in-out all 0.3s;   

      }

      .itemPlaceholderB:hover {

        outline: 2px solid #84e8febd; 
        box-shadow: 0px 0px 2px rgba(134, 226, 208, 0.96);
        transition: ease-in-out all 0.3s; 
        /*cursor: url('./images/clay-cursor28.png'),auto;*/
      }


      .trousersB {
        margin-top: -40px;
        width: 75%;
        height: auto;
        filter:  drop-shadow(2px 3px 2px #0000006e);



        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -khtml-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none;


        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;

      }


      .itemAInfo {

        display: flex;
        color: #82cfe3;
        font-family: ClayPay;
        line-height: 0.3;
      

      }


      .tokenImg {

width: 40px;
margin-top: 5px;

      }


      .price {

        color: #d3eef564;
        font-family: ClayPay;
      
        text-shadow: 0px 0px 3px #68daf4a6;
    
        letter-spacing: 0.1rem;
        font-weight: 100;
        
        font-size: 2.4rem;
        margin-left: 10px;
        
      }


      .itemBInfo {

        display: flex;
        color: #d3eef564;
    font-family: ClayPay;
        line-height: 0.3;
        margin-left: 450px;
        margin-top: -92px;

      }


      .hrline {


    
        left:170px;
        top: 170px;
        color:white;
        height:2px;
    
        width: 80%;
    
        border: 0px solid #84e8fe32;
        background-color: #84e8fe24;
    
        border-radius: 22px;
    
       }


       .subtotalSection {

        display: flex;
        
        align-items: center;

        flex-direction: column;
       
        
           }


           .subtotalTxt   {

            
        
            color: #d3eef564;
            font-family: ClayPay;
            font-size: 1.8rem;
            text-shadow: 0px 0px 7px #68daf4a6;
        
            letter-spacing: 0.1rem;
            font-weight: 100;
            
        
            
            font-size: 2.4rem;
            
            transition: ease-in-out all 0.3s; 
            
        
           }


           .tokenImgSubtotal  {

            width: 40px;
        
            margin-left: 30px;
            margin-top: 5px;

           }

           .subtotal {

            color: #d3eef564;
            font-family: ClayPay;
            font-size: 1.8rem;
            text-shadow: 0px 0px 3px #68daf4a6;
        
            letter-spacing: 0.1rem;
            font-weight: 100;
            
        
            
            font-size: 2.4rem;

            margin-left: 10px;
            
            transition: ease-in-out all 0.3s; 
            

           }


           .submit {

    display: block;
    align-items: center;


    object-fit: cover;
    object-position: bottom;

    position: relative;

  



           }

    .buttonSubmit {
        font-family: ClayPay;
        padding-left: 30px;
        padding-right: 30px;
        background-color: #426772;
        border: none;
        border-radius: 4px;
        outline: 4px solid rgba(0, 0, 0, 0.582) ;
        font-size: 3rem;
        color: #192025;
        letter-spacing: 0.1rem;
        transition: ease-in-out all 0.3s; 
        opacity: 0.9;
    }



    .buttonSubmitDisabled {
        font-family: ClayPay;
        padding-left: 30px;
        padding-right: 30px;
        background-color: #426772;
        border: none;
        border-radius: 4px;
        outline: 4px solid rgba(0, 0, 0, 0.582) ;
        font-size: 3rem;
        color: #192025;
        letter-spacing: 0.1rem;
        transition: ease-in-out all 0.3s; 
        opacity: 0.9;
    }






           .buttonSubmit:hover {

            color: #84E8FE;
            transition: ease-in-out all 0.3s;
            
            text-shadow: 0px 0px 2px #84e8fed5;
            
           /* cursor: url('./images/clay-cursor28.png'),auto;*/
            
            }
            
            
            .buttonSubmit:active{
             
            
              transition: none;
            }
            



.paymentOption {


width:250px;
height:250px;

background-color: #132328;

align-items: center;
justify-content: center;

display: flex;

border-radius: 13px;

outline: 3px solid rgba(0, 0, 0, 0.2) ;

transition: ease-in-out all 0.3s;   

      }

      .paymentOption:hover  {

        outline: 2px solid #84e8febd; 
        box-shadow: 0px 0px 2px rgba(134, 226, 208, 0.96);
        transition: ease-in-out all 0.3s; 

        cursor: pointer;

        /*cursor: url('./images/clay-cursor28.png'),auto;*/
      }




.paymentOptionSelected{
    width:250px;
    height:250px;
    background-color: #132328;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 13px;
    outline: 3px solid rgba(0, 0, 0, 0.2) ;
    transition: ease-in-out all 0.3s;  
    outline: 2px solid #84e8febd; 
    box-shadow: 0px 0px 2px rgba(134, 226, 208, 0.96);
    transition: ease-in-out all 0.3s; 
    cursor: pointer;
}




.methodTypeTxt
  {

            
        
            color: #d3eef564;
            font-family: ClayPay;
            font-size: 1.8rem;
            text-shadow: 0px 0px 7px #68daf4a6;
        
            letter-spacing: 0.1rem;
            font-weight: 100;
            
        
            
            font-size: 20px;
            
            transition: ease-in-out all 0.3s; 
            
        
           }


           .totalPaymentTxt {

            color: #d3eef564;
            font-family: ClayPay;
            font-size: 1.8rem;
            text-shadow: 0px 0px 3px #68daf4a6;
        
            letter-spacing: 0.1rem;
            font-weight: 100;
            
        
            
            font-size: 38px;

            margin-left: 10px;
            
            transition: ease-in-out all 0.3s; 
            

           }





