.popup_2 {

position: fixed;
top: 0;
left: 0;
width:100%;
height: 100vh;
background-color: rgba(0, 0, 0, 0.2);
display: flex;
justify-content: center;
align-items: center;

backdrop-filter: blur(45px);

z-index: 2;

}

.popup-inner_2 {

    width: 70%;
    height: 70%;

    min-height: 400px;
    min-width: 250px;

    position: relative;
    padding: 32px;
   
    background: linear-gradient(180deg, rgba(51,37,70,0.5) 0%, rgba(43,54,78,0.5) 50%, rgba(29,58,61,0.5) 100%);
    backdrop-filter: blur(15px);
   
    border-radius: 22px;
    box-shadow: inset 12px -12px 12px rgba(0, 0, 0, 0.3), inset -12px 0.9px 12px rgba(0, 0, 0, 0.3);

}

.popup-inner_2 .close-btn {

position: absolute;
top: 20px;
right: 26px;
background-color: transparent;
border: none;
color:bisque;

font-size: 1.3em;
opacity: 0.8;   

cursor: pointer;

}

.Question 
    {
        position: relative;

        
        
      z-index: 2;
      font-size: 1em;
      font-family: 'Gloria';
      letter-spacing: 0.1em;

      text-align: center;

      
        color: black;
        
        
    } 




.vote-btn {



    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;

    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    outline: 0px;
    border: 20px;
    

    cursor: pointer;
    position: relative;
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: #15222f;
    overflow: hidden;
    border-radius: 10px;
    opacity: 0.9;
    
    color:bisque;
    
    
      
    
    }
    
    
    
    
    .vote-btn:before
    {
        content: '';
        position: absolute;
        width: 200px;
        height: 110%;
        background: linear-gradient(180deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 100%);
        opacity: 0.9;

        
    
    
        animation: animate 7s linear infinite;
    }
    
    
    @keyframes animate 
    {
        0%
        {
            transform: rotate(0deg);
        }
        100%
        {
            transform: rotate(360deg);
        }
    }
    
    
    
    .vote-btn:after
    {
        content: '';
        position: absolute;
        inset: 2px;
    
        background: linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%);

        box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    
    
        border-radius: 10px;

       
    }
    
    
    
    h1
    {
        position: relative;
        z-index: 2;
        font-size: 1em;
      font-family: 'shortstack';
      letter-spacing: 0.1em;
      
        color: bisque;

        text-transform: uppercase;
        
        
    }
    
    
    .vote-btn:hover
    {
        position: relative;
        width: 100px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: #2d4359;


      overflow: hidden;
      border-radius: 10px;
      opacity: 0.9;
      
    }
    
    
    .vote-btn:hover:before
    {
        content: '';
        position: absolute;
        width: 100px;
        height: 250%;
        background: linear-gradient(-180deg,#f98763,#9482F4,#84D3FF);
        opacity: 1;
        animation: none;
    
       
      
    }
    
    
    .vote-btn:active
    {
        position: relative;
        width: 100px;
        height: 50px;
      
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2d4359;
    
      overflow: hidden;
        border-radius: 10px;
      opacity: 0.9;
      
    }
    
    
    .vote-btn:active:before
    {
        content: '';
        position: absolute;
        width: 100px;
        height: 250%;
        background: linear-gradient(-180deg,#f98763,#9482F4,#84D3FF);
        opacity: 1;
        animation: none;
    
       
      
    }
    
    
    .vote-btn:active:after
    {
        content: '';
        position: absolute;
        inset : 2px;
        background: linear-gradient(-30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
        
        box-shadow: inset -7px -7px 38px rgba(0, 0, 0);

        border-radius: 10px;
        
    }
    

.submit-btn {

    
    display: block;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;

    float: center; 


    
    margin: 0 auto;

    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    outline: 0px;
    border-top: 2px solid rgb(162, 246, 248, 0.3);
    border-left: 2px solid rgba(148,130,244, 0.5);
    border-bottom: 2px solid rgba(246,141,141, 0.5);
    border-right: 2px solid rgba(148,130,244, 0.5);
   



    
    cursor: pointer;

    user-select: none;

    vertical-align: auto;
    appearance: none;
    text-decoration: none;

    
    font-weight: 500;
    font-size: 0.9375rem;

    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    min-width: 64px;

    padding: 10px 20px;

    border-radius: 14px;
    
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color:bisque;


    background: linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);



      
    
    }


    .submit-btn:hover {


         box-shadow: inset -7px -7px 38px rgba(0, 0, 0);



    }




.purple-btn {

    
    display: block;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;

    float: center; 

    top: 120px;
    
    margin: 0 auto;

    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    outline: 0px;
    border-top: 2px solid rgb(162, 246, 248, 0.3);
    border-left: 2px solid rgba(148,130,244, 0.5);
    border-bottom: 2px solid rgba(246,141,141, 0.5);
    border-right: 2px solid rgba(148,130,244, 0.5);
   

    
    cursor: pointer;

    user-select: none;

    vertical-align: auto;
    appearance: none;
    text-decoration: none;

    
    font-family: 'Gloria';
    font-weight: 300;
    font-size: 0.9375rem;

    line-height: 1.75;
    letter-spacing: 0.5em;
    

    min-width: 164px;

    padding: 10px 100px;

    border-radius:  34px;
    
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;  
    color:bisque;


    background: transparent;
    

    
    }



    .purple-btn:hover {

        background: linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%);


        box-shadow: inset -7px -7px 38px rgba(0, 0, 0), 0px 0px 28px rgba(139, 182, 224, 0.5);

    


    }


    .purple-btn:focus {

        background: linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%);


        box-shadow: inset -7px -7px 38px rgba(0, 0, 0), 0px 0px 28px rgba(139, 182, 224, 0.5);


        



    }



.black-btn {

    
    display: block;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;

    float: center; 

    margin: 0 auto;

    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    outline: 0px;
    border-top: 2px solid rgb(162, 246, 248, 0.3);
    border-left: 2px solid rgba(148,130,244, 0.5);
    border-bottom: 2px solid rgba(246,141,141, 0.5);
    border-right: 2px solid rgba(148,130,244, 0.5);
   

    
    cursor: pointer;

    user-select: none;

    vertical-align: auto;
    appearance: none;
    text-decoration: none;

    
    font-family: 'Gloria';
    font-weight: 300;
    font-size: 0.9375rem;

    line-height: 1.75;
    letter-spacing: 0.5em;
    

    min-width: 164px;

    padding: 10px calc(30px + 5vw);

    border-radius:  34px;
    
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;  
    color:bisque;


    background: transparent;
    



      

    }



  .black-btn:hover {

        background: linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%);



        box-shadow: inset -7px -7px 38px rgba(0, 0, 0), 0px 0px 28px rgba(139, 182, 224, 0.5);




    }


    .black-btn:focus {

        background: linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%);


        box-shadow: inset -7px -7px 38px rgba(0, 0, 0), 0px 0px 28px rgba(139, 182, 224, 0.5);  


        
    }



 .selectedButton{




        display: block;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;

    float: center; 

    margin: 0 auto;

    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    outline: 0px;
    border-top: 2px solid rgb(162, 246, 248, 0.3);
    border-left: 2px solid rgba(148,130,244, 0.5);
    border-bottom: 2px solid rgba(246,141,141, 0.5);
    border-right: 2px solid rgba(148,130,244, 0.5);
   
    cursor: pointer;
    user-select: none;
    vertical-align: auto;
    appearance: none;
    text-decoration: none;
    font-family: 'Gloria';
    font-weight: 300;
    font-size: 0.9375rem;

    line-height: 1.75;
    letter-spacing: 0.5em;
    

    min-width: 164px;

    padding: 10px calc(30px + 5vw);

    border-radius:  34px;
    
    color:bisque;

    background: linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), 0px 0px 28px rgba(139, 182, 224, 0.5);  


 }











