.voicebtn{
  width:90px;
  height:90px;
  font-family: ShortStack;
  border: 5px solid #4c547188;
  border-radius: 50px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)), rgba(151, 218, 247, 0.136);
  border: 2px solid #b7ffc1;
  backdrop-filter: blur(6px);
/*  cursor: url(cursoractive.png),auto;*/
}


.voicebtn:hover {
  outline: 3px solid #B7FFC1; 
  filter: drop-shadow(0px 0px 14px rgba(183, 255, 193, 0.76));
}


.voicebtn:focus {
  outline: 3px solid #B7FFC1; 
  filter: drop-shadow(0px 0px 14px rgba(183, 255, 193, 0.76));
}


.voiceSelectionTool {
  align-items: center;
  position: relative;
  left: 570px;
  top:-270px;
}


.micimg {
  width: 60px;
  height: auto;
  opacity: 0.9;
}


.voiceOptions {
  display: none;
}


.voicebtn:hover + #voiceOption {
  align-items: center;
  margin-top: 15px;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 10px;
  transition: all 2s ease;
}


.voiceOptions {
  align-items: center;
  margin-top: 15px;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 10px;
  transition: all 2s ease;
}


#voiceOption{
  width:90px;
  height:90px;
  font-family: ShortStack;
  border: 5px solid #4c547188;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(6px);
/*  cursor: url(cursoractive.png),auto;*/
  font-size: 1.5rem;
  color: white;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.37);
  align-items: center;
}


  #voiceOption:hover{
/*    cursor: url(cursoractive.png),auto;*/
    transition: all ease-in-out 0.2s;
    border: 5px solid #B7FFC1; 
  }





#selectedVoiceOption{
  width:90px;
  height:90px;
  font-family: ShortStack;
  border: 5px solid #4c547188;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(6px);
/*  cursor: url(cursoractive.png),auto;*/
  font-size: 1.5rem;
  color: white;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.37);
  align-items: center;

  outline: 3px solid #B7FFC1; 
  filter: drop-shadow(0px 0px 14px rgba(183, 255, 193, 0.76));
}



.tooltipVoice {
  color: rgba(255, 255, 255, 0.739);
  position: absolute;
  top:-22px;
  left: 60px;
  font-size: 0.6rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  white-space: nowrap;
  text-align: center;
  margin: 0 auto;
  backdrop-filter: blur(6px);
}



