


.title {
  color: rgba(255, 255, 255, 0.6);
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  font-weight: 400;
  font-size: 22px;
  font-family: 'ShortStack';
}


.line-left {
  width: 280px;
  height: 10px;
  color: white;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-image: linear-gradient(-90deg, rgba(25,25,25,0.5) 0%, rgba(53,38,61,0.03547356442577032) 100%) 1;
  position: relative;
  top:15px;
  left:-250px;
  border-radius: 1px;
}


.line-right {
  width: 280px;
  height: 10px;
  color: white;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-image: linear-gradient(90deg, rgba(25,25,25,0.5) 0%, rgba(53,38,61,0.03547356442577032) 100%) 1;
  position: relative;
  top:-25px;
  left:250px;
  border-radius: 1px;
}
