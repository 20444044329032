.cn-toggle {
    position: relative;
    height: 35px;
    width: 140px;
    border-radius: 50px;
    border: 1px solid rgba(18, 18, 18, 0.113);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), linear-gradient(180deg, rgba(51, 37, 70, 0.2) 0%, rgba(43, 54, 78, 0.2) 50%, rgba(29, 58, 61, 0.2) 100%);
    box-shadow: inset 0px -0.826107px 4.95664px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px -2px 12px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}


.notch-CN {
    height: 35px;
    width: 70px;
    border-radius: 50px;
    background-color: #ff9c83;
    position: relative;
    top: 0px;
    left: 0px;
    box-shadow: 0px 0px 3px rgba(254, 119, 119, 0.674), inset -6px 4px 6px #f97a5abe, inset -6px -5px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.CN {
    position: relative;
    font-size: 1rem;
    color: bisque;
    text-shadow: 3px 0px 2px rgba(0, 0, 0, 0.1);




}
   

.GC-toggle {
    position: relative;
    top: 200px;
    left: -400px;
    height: 35px;
    width: 140px;
    border-radius: 50px;
    border: 1px solid rgba(18, 18, 18, 0.113);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), linear-gradient(180deg, rgba(51, 37, 70, 0.2) 0%, rgba(43, 54, 78, 0.2) 50%, rgba(29, 58, 61, 0.2) 100%);
    box-shadow: inset 0px -0.826107px 4.95664px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px -2px 12px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.notch-GC {
    height: 35px;
    width: 70px;
    border-radius: 50px;
    background-color: #53C9EE;
    position: relative;
    left: 69px;
    box-shadow: 0px 0px 3px #53C9EE, inset -6px 4px 6px #53C9EE, inset -6px -5px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GC {
    position: relative;
    font-size: 1rem;
    color: bisque;
    text-shadow: 3px 0px 2px rgba(0, 0, 0, 0.1)
}



















.filter-btn {


    width: 250px;
    height: 50px;

    background: rgba(217, 217, 217, 0.49);

    border-radius: 18px;


   -webkit-tap-highlight-color: transparent;


   outline: none;

   border: 18px;

   cursor: pointer;


   background: linear-gradient(0deg, rgba(54, 75, 93, 0.84), rgba(54, 75, 93, 0.84)), rgba(0, 0, 0, 0.04);
    
   border: 4px solid rgba(17, 19, 35, 0.26);


   box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.19);
    
   
    border-radius: 10px;

    animation: cat-animation 0.8s cubic-bezier(0.255, 0.450, 0.460, 0.950) both;

    -webkit-animation: cat-animation 0.8s cubic-bezier(0.255, 0.450, 0.460, 0.950) both;



  }



  .filter-txt {



font-size: 15px;

color: #fffce5ee;

font-style: normal;
font-weight: 400;


letter-spacing: 0.355em;
text-transform: uppercase;


position: relative;

float: left;

left: 20px;




  }


.arrow-down {


float: right;

opacity: 0.9;

position: relative;
left:-20px;
top: 5px;


}





.filter-btn:hover {


    box-shadow: 0px 0px 10px #7DF9E2;


}


















.filter-dropdown {

    position:relative;
    width: 238px;
    height: 162px;



    background: linear-gradient(0deg, #364B5D, #364B5D), #000000;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.13);


    align-items: center;


    

}





.checkbox {



float: right;


position:relative;  

left: -15px;

opacity: 0.8;

top: 8px;

cursor: pointer;

}


.dropdown-atr {


    width: 100%;
    height: 40px;

    border-width: 0px 1px 2px 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);


    
}




.dropdown-1 {


    width: 238px;
    height: 40px;

    border-width: 0px 1px 2px 1px;
border-style: solid;
border-color: rgba(0, 0, 0, 0.1);


    
}



.dropdown-2 {


    width: 238px;
    height: 40px;

    border-width: 0px 1px 2px 1px;
border-style: solid;
border-color: rgba(0, 0, 0, 0.1);
}





.dropdown-3 {


    width: 238px;
    height: 40px;

    border-width: 0px 1px 2px 1px;
border-style: solid;
border-color: rgba(0, 0, 0, 0.1);
}




.dropdown-4 {


    width: 238px;
    height: 40px;

    border-width: 0px 1px 2px 1px;
border-style: solid;
border-color: rgba(0, 0, 0, 0.1);
}



.filter-txt1 {


    font-size: 14px;

    position: relative;



    color: bisque;
}


.filter-txt2 {


    font-size: 14px;

    position: relative;

    top: 8px;

    left: 8px;

    color: bisque;
}



.filter-txt3 {


    font-size: 14px;

    position: relative;

    top: 8px;

    left: 8px;
    
    color: bisque;

}

.filter-txt4 {

    font-size: 14px;

    position: relative;

    top: 8px;

    left: 8px;

    color: bisque;
}












@-webkit-keyframes cat-animation {
    0% {
  
      opacity: 0;
  
  
      
      transform: translateX(-100px);
  
  
      -webkit-transform: translateX(-100px);
      
    }
    100% {
  
  
      opacity: 1;
  
  
     
  
  
      transform: translateX(0);
  
  
  
      -webkit-transform: translateX(0);
      
    }
  }
  @keyframes cat-animation {
    0% {
  
  
      opacity: 0;
  
  
      transform: translateX(-100px);
      -webkit-transform: translateX(-100px);
    
     
    }
    100% {
  
  
      opacity: 1;
  
      transform: translateX(0);
      -webkit-transform: translateX(0);
   
      
    }
  }














.cn-toggle-on {
    position: relative;
    height: 25px;
    width: 60px;
    border-radius: 50px;
    border: 1px solid rgba(229, 99, 99, 0.215);
    background-color: #ff9c83bc;
    box-shadow: 0px 0px 3px rgba(254, 119, 119, 0.674),inset 4px 4px 3px rgba(138, 66, 66, 0.25);
    cursor: pointer;
}


.notch-CN-on {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background-color: #ff9c83;
    position: relative;
    top: -4px;
    left: 28px;
    box-shadow: 0px 0px 3px rgba(254, 119, 119, 0.674), inset -6px 4px 6px #f97a5abe, inset -6px -5px 3px rgba(0, 0, 0, 0.2);
}

.cn-toggle-off {
    position: relative;
    height: 25px;
    width: 60px;
    border-radius: 50px;
    border: 1px solid rgba(14, 14, 14, 0.178);
    background-color: #959595bc;
    box-shadow: inset -4px 4px 3px #53505053;
    cursor: pointer;
}


.notch-CN-off {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background-color:  #918f8ff1;
    border: 1px solid rgba(14, 14, 14, 0.178);
    position: relative;
    top: -6px;
    left: -10px;
    box-shadow: inset -6px 4px 6px  #95959591, inset 6px -5px 3px rgba(0, 0, 0, 0.2);
}

.gc-toggle-on {
    position: relative;
    height: 25px;
    width: 60px;
    border-radius: 50px;
    border: 1px solid #43a9c84b;
    background-color: #53C9EE;
    box-shadow: 0px 0px 3px #53C9EE, inset 4px 4px 3px rgba(84, 83, 83, 0.25);
    cursor: pointer;
}

.notch-GC-on {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background-color: #53C9EE;
    border: 1px solid #3c859b16;
    position: relative;
    top: -6px;
    left: 28px;
    box-shadow: 0px 0px 3px #46cff8, inset -6px 4px 6px #53C9EE, inset -6px -5px 3px rgba(0, 0, 0, 0.2);
}


