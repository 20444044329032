
.Outer{
	position: absolute; 
	display: flex;
	left: 0px;
	top: 10px;
	z-index: 100;
	min-width: 300px;
	width: 40%; 
	height: 95%;

	font-family: ShortStack;

	-ms-overflow-style: none; 
  	scrollbar-width: none;
}


.Outer::-webkit-scrollbar {
  display: none;
}

.DevMode{
	font-size: 20px; 
	color: red;
	margin-left: 40px;
	margin-top: 10px;
	margin-bottom: -20px;

	user-select: none;
}




.UpOuterSmall{
	position: absolute; 
	display: flex;
	left: 0px;
	top: 20px;
	z-index: 100;
	min-width: 300px;
	width: 100%; 
	height: 80px;
	font-family: ShortStack;
}


.BottomOuterSmall{
	position: absolute; 
	display: flex;
	left: 0px;
	bottom: 0px;
	z-index: 100;
	min-width: 300px;
	width: 100%; 
	height: 350px;
	font-family: ShortStack;

	-ms-overflow-style: none; 
  	scrollbar-width: none;
}


::-webkit-scrollbar {
  display: none;
}
