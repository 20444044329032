


.placeholder-comingsoon {



    text-align: center;
    align-items: center;
    justify-content: center;


  display: inline-block;
  width: 70%;
  height: 500px;

  position: relative;


  background: rgba(67, 89, 111, 0.109);



  background: rgba(67, 89, 111, 0.3);


  border: 2px solid pink;
  border-image: linear-gradient(90deg, rgb(146, 204, 236, 0.5) 0%, rgba(148,130,244,0.5) 49%, rgba(246,141,141,0.5) 80%);
  border-image-slice: 1;
      
  backdrop-filter: blur(20px);


}



.coming-soon {



position: relative;

top: 40%;


font-size: 2rem;
letter-spacing: 1rem;





background: -webkit-linear-gradient(0deg, rgb(146, 204, 236, 0.7) 0%, rgba(148,130,244,0.7) 49%, rgba(246,141,141,0.7) 80%);


  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


}






div.track-GC, div.day {
    box-sizing: border-box;
    position: relative;
}
 
div.progress-GC {
    width: 142px;
    padding-left: 2px;
    padding-right: 2px;
    height: 25px;
    border-radius: 7px;
    border: solid 2px rgba(148,130,244,0.8);
    background-color: #1B252B;
    position: relative;
    top: 240px;

    margin: auto;


}
 
div.track-GC {
    position: relative;
    width: 200px;
    height: 25px;
    overflow: hidden;
    
    animation: prgBar 14s linear 0s infinite;
}
 
div.day {
    height: 19px;
    width: 3px;
    background-color: #0099EF;
    position: relative;
    border-radius: 2px;
    box-shadow: 0px 0px 3px #0099EF;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 100px;
    top: 3px;
    float: left;
    clear: top;
}
 

 
@keyframes prgBar {
    0%    { width: 0% }
    
    95%   { width: 100% }
}

