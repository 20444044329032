.clear-btn {


    width: 170px;
    height: 50px;


    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;   
    background: linear-gradient(0deg, #5A9D9C, #5A9D9C), rgba(217, 217, 217, 0.72);
    box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.11), inset -6px 4px 6px rgba(152, 171, 171, 0.5), inset 4px -5px 3px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    outline: none;
    border: 18px;
    cursor: pointer;

}

.clear-txt {
    font-size: 15px;
    margin:auto;
    color: bisque;
    align-items: center;
    text-align: center;
    letter-spacing: 0.355em;
    text-transform: uppercase;
}

.clear-btn:hover {

    box-shadow: 3px 4px 11px rgba(0, 0, 0, 0.3), inset -6px 4px 6px rgba(152, 171, 171, 0.5), inset 4px -5px 3px rgba(0, 0, 0, 0.16);

}


.clear-btn:active {
  transform: translateY(2px);
}