   

   .GC-flip-card {
        background-color: transparent;
        width: 272px;
        height: 384px;
        perspective: 1000px;
        border-radius: 10px;
        text-align: center;

    }
    
    .GC-flip-card-inner {
        position: relative;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;

        width: 272px;
        height: 384px;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 0 0 22px 7px rgba(83, 201, 238, 0.3); 
    }
    
    .GC-flip-card_hover .GC-flip-card-inner {
        transform: rotateY(180deg);
    }


    .cardLow{
        transition-delay: 0.2s;
    }


    .GC-flip-card_hover .cardLow {
        transition: transform 0s;
        transition-delay: 0.2s;
        transform: rotateY(180deg);
    }




    .GC-flippedCard{

        position: relative;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        width: 272px;
        height: 384px;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 0 0 22px 7px rgba(255, 181, 181, 0.3); 


        transform: rotateY(180deg);

    }



    .GC-flippedCard .cardLow {
        transition: transform 0s;
        transition-delay: 0.2s;
        transform: rotateY(180deg);
    }




    .GC-flip-card-front, .GC-flip-card-back {
        position: absolute;
        text-align: center;
        border-radius: 10px;
        width: 272px;
        height: 384px;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    

    .GC-flip-card-front {
        color: black;
        display: block;
        background-color: transparent;

        text-align: center;
    }
    
    .GC-flip-card-back {
        background-color:transparent;
        color: white;


        border-radius: 10px;

        box-shadow: 0 0 22px 7px #53caee9f; 


        border: 3px solid #53C9EE;

        transform: rotateY(180deg);

        width: 270px;
        height: 382px;

        text-align: center;

        margin-left: -2px;
        margin-top: -2px;


    }



    .GC-clay-nft {

    position: relative;


    display: block;


    width: 270px;
    height: 270px;

    margin-top: 1px;
    margin-left: 1px;


    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    
    }


    .GC-clay-nft img{

        display:block;

    }



    .GC-clay-id {

    margin-top: 2px;
    position: relative;
    font-family: ShortStack;
    font-size: 1.2rem;
    color:#AEE8FB;
    opacity:0.9;

}



.GC-collection-back {


    color:#AEE8FB;
    
    font-size: 16px;
    font-family:ShortStack; 


}





.GC-clay-id-back {

  color:#AEE8FB;
  font-size: 1rem;
  font-family:ShortStack; 

}



      .GC-clay-nft-back {


        width: 100px;

        border-radius: 6px;

      }





      .GC-clay-properties {

        align-items: left;
        
        
        font-size: 14px;
        
        
        display: flex; 
        justify-content: center; 
        text-align: left;



        line-height: 1;


        position: relative;

        left: 0;
        right: 0;
        margin: 0 auto;

    
    
   
        
          }
        
        
          .GC-clay-percentage {
        
            font-size: 16px;
            display: flex;
            justify-content: center;
            text-align:right;
            color: #AEE8FB;
            line-height: 1.2;
            position: relative;
            left: 0;
            right: 0;
            margin: 0 auto;
        
          }


          .GC-properties {


            position: relative;

            font-family: 'ShortStack';


          }
        
        

    
        

          .GC-price-tag-back {

            background-color: transparent;
            
            border: 1px solid transparent ;
            
            width: 134px;

            text-align: center;
            
            letter-spacing: 0.2;
            
            border-radius: 10px;
            
                        position: absolute;
                        
                        left: -10px;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: -30%;
                        
                    
            
            
                        font-size: 1.1rem;
            
            font-family:ShortStack; 
                        
                        
            
            padding: 3px;
            
            
                        display: inline-flex;
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        position: relative;
                    
                        box-sizing: border-box;
                        -webkit-tap-highlight-color: transparent;
                    
                    
                        outline: none;
                    
            
                    
            
            
                       cursor: pointer;
                       
                        
                          }


                          .GC-pricetag {


                            position: absolute;

                            left: 0;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 0px;
                        
                            width: 100%;

                            display:block;

                          }


                          .GC-ADA-front {


                    

                            position: absolute;

                            left: -2%;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 98%;   

                            width: 10%;

                          }

            


                          .GC-ADA-back {


                            z-index: 1;


                            position: relative;

                            left: -12%;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 22px;   

                            width: 17%;

                          }




                          .GC-amount {

                            position: absolute; 

                            left: 33%;  
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 98%;


                            font-size: 1.1rem;
            
                            font-family:ShortStack; 

                            opacity: 0.9 ;

                          }


                          .GC-amount-back {


                            color: black;
                            position: absolute; 

                            left: 35%;  
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 90%;

                            opacity: 0.9 ;  

                            z-index: 1;
                          }
            
            

                          .GC-price-back {

                            position: absolute; 

                            left: 0;
                            right: 0;
                            margin: 0 auto; 

                            top: 81%;
                            
                          }
                          


                          .GC-tokens {



                            color: white;
                            opacity: 0.9 ;

                            font-size: 14px;
            
                            font-family:ShortStack; 



                          }



                          .GC-price-tag-back:hover .GC-pricetag  {

                            display:none;
                           

                          }


                          .GC-price-tag-back:hover .GC-ADA-back  {
                            display:none;
                            cursor: pointer;

                          }


                          .GC-price-tag-back:hover span {
                            display:none

                          }
                          
                          .GC-price-tag-back:hover:before {
                            content:"BUY NFT";

                            font-size: 1rem;
                            font-family:ShortStack; 
                         
                      

                          }
                          
                          .GC-price-tag-back:hover {


                            background-color: rgba(0, 0, 0, 0.688);


                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            border: 1px solid black;
                            color: white;

                        
                            padding: 4px;

                            font-family:ShortStack; 


                            position: relative;
                        
                           left: 0;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 22px;
                        
                    
                            width: 50%;


                          }





.GC-SellBtn{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    width: 130px;
    height: 55px;
    border: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(117, 151, 191);
    z-index: 3;
    border-radius: 18px;
    position: relative;
    left: 0px;
     box-shadow:  inset 10px 5px 8px rgba(117, 151, 191, 0.3 ), inset -7px -4px 6px rgba(0, 0, 0, 0.3 );
    opacity: 1;   
    cursor: pointer;
   
}


  .GC-SellBtn:before {
    content: '';
    background: linear-gradient(90.09deg, #FDBCAC, rgba(148, 130, 244, 0.950741), #84D3FF , rgba(148, 130, 244, 0.950741) , rgba(246, 141, 141, 0.89) );
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(3px);
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    animation: glowing 10s linear alternate-reverse infinite;
    opacity:0;
    transition: opacity .3s ease-in-out;
    border-radius: 18px;
}



.GC-SellBtn:hover:before {
    opacity: 0;
  
}

.GC-SellBtn:after {
    z-index: -1;
    content: '';
    position: absolute;
    background: rgba(117, 151, 191);
    left: 0;
    top: 0;
    border-radius: 18px;
    overflow: hidden;
    inset: 2px;
    box-shadow:  inset 10px 5px 8px rgba(117, 151, 191, 0.3 ), inset -7px -4px 6px rgba(0, 0, 0, 0.3 );

}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 300% 0; }
    100% { background-position: 0 0; }
}


.GC-SellBtn:focus::after {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(117, 151, 191, 0.8);
    cursor: pointer;
    z-index: 0;
    border-radius: 18px;
}



.GC-SellBtn:focus {
    box-shadow:  inset -5px -5px 9px rgba(117, 151, 191, 0.8), inset 5px 5px 10px rgba(0, 0, 0, 0.4);
}


.GC-SellBtn:focus::before {
    animation: none;
    display: none;
    text-decoration: none;
}


.GC-SellBtn h1 {
   font-size: 1rem;
   color:bisque;
   text-shadow: 0 0 5px rgba(15, 14, 14, 0.3), 0 2px 1px #457ab254;
}




.buttonFront{
    max-height: 500px;
}

.GC-flip-card:hover .buttonFront {
    max-height: 0;
    transition: max-height 0.6s ease-out;
    overflow: hidden;

}


      