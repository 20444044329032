

.boosting-pool-png {

  width: 65%;
}




.boosting-pool {



position:relative;

margin-left: -80px;


width: 310px;


}



.clay-price-number {

position: relative;
float: right;

padding-right: 20px;
padding-top: 16px;

color: rgba(255, 255, 255, 0.866);


}


.clay-price-tag {

  position: relative;
  float: left;
  
  padding-left: 2px;
  padding-top: 17px;

  color: #ccc;

  font-size: 0.9rem;

  font-family: shortstack;
  
  }
  

  .token-price-img {
    width: 12%;
    float: left;
    padding-top: 13px;
    padding-left: 10px;
    opacity: 0.9;
  }







.token-price {

  
  font-family: shortstack;


  width: 250px;
  height: 50px;
  border-radius: 10px;

  background-color: rgba(255, 255, 255, 0.1);  
  backdrop-filter: blur(5px);

  border-radius: 10px;

  box-shadow: 0px 0px 0px #2999d148;

  -webkit-animation-name: bounceInLeft; 
      animation-name: bounceInLeft; 
  -webkit-animation-duration: 1s;
  	animation-duration: 1s;
  	-webkit-animation-fill-mode: both;
  	animation-fill-mode: both;
  	-webkit-animation-timing-function: ease-in;
  	animation-timing-function: ease-in;



}


@keyframes bounceInLeft { 
  0% { 
      opacity: 0; 
      transform: translateX(-200px); 
  } 
  60% { 
      opacity: 1; 
      transform: translateX(10px); 
  } 
  80% { 
      transform: translateX(-10px); 
  } 
  100% { 
      transform: translateX(0); 
  } 
} 









.market-cap-tag {

  position: relative;
  float: left;
  
  padding-left: 15px;
  padding-top: 18px;

  color: #ccc;

  font-size: 0.8rem;
  font-family: shortstack;
  
  }




.market-cap-number {

  position: relative;
  float: right;
  
  padding-right: 20px;
  padding-top: 13px;
  
  color: rgba(255, 255, 255, 0.866);
  font-family: shortstack;
  
  }

.market-cap {


  width: 250px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);  
  backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: 0px 0px 0px #2999d148;

  -webkit-animation-name: bounceInLeft; 
  animation-name: bounceInLeft; 
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}



.dilluted-market-cap-tag {

  position: relative;
  float: left;
  
  padding-left: 15px;
  padding-top: 12px;

  color: #ccc;

  font-size: 0.7rem;
  font-family: shortstack;
  
  }




.dilluted-market-cap-number {

  position: relative;
  float: right;
  
  padding-right: 20px;
  padding-top: 13px;
  
  color: rgba(255, 255, 255, 0.866);

  font-family: shortstack;
  
  }

.dilluted-market-cap {

  width: 250px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);  
  backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: 0px 0px 0px #2999d148;

  -webkit-animation-name: bounceInLeft; 
  animation-name: bounceInLeft; 

  -webkit-animation-delay: 1s;
  animation-delay: 1s;

  -webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;

}



.baking-activity {

width: 100%;
overflow: scroll;

}





.accounts-txt {

  font-size: 0.7em;
  color: rgba(204, 204, 204, 0.685);
    
  font-family: shortstack;




}



.accounts {


position: relative;

top: 0px;

}




.accounts-clay {


  font-size: 1.8em;
  color: #b27cf0;
  
  font-family: shortstack;

  position: relative;
  

text-shadow: 1px 1px #282c3449;

animation-name: text-focus-in;
animation-duration: 2s;
animation-timing-function: ease;
animation-delay: 0s;
animation-iteration-count: 1;
animation-direction: normal;
animation-fill-mode: none;
/* shorthand 
animation: text-focus-in 1s ease 0s 1 normal none;
*/ 



}



.clay-baked {


  font-size: 1.8em;
  color: #5593d6;
  
  font-family: shortstack;

  position: relative;
  
left: -5px;
text-shadow: 1px 1px #282c3449;


animation-name: text-focus-in;
animation-duration: 2s;
animation-timing-function: ease;
animation-delay: 0s;
animation-iteration-count: 1;
animation-direction: normal;
animation-fill-mode: none;
/* shorthand 
animation: text-focus-in 1s ease 0s 1 normal none;
*/ 


}

.clay-baking {



padding-left:0px;


}

.assets-baking-txt {

position: relative;

left:-35px;


}


.assets-baking {



display: flex;

position: relative;
top: 0px;


  }
  




.clay-baking-number {


font-size: 1.8em;
color: #8cb7e5;

font-family: shortstack;

text-shadow: 1px 1px #282c3449;

animation-name: text-focus-in;
animation-duration: 2s;
animation-timing-function: ease;
animation-delay: 0s;
animation-iteration-count: 1;
animation-direction: normal;
animation-fill-mode: none;
/* shorthand 
animation: text-focus-in 1s ease 0s 1 normal none;
*/ 



}


.clay-baking-txt {


font-size: 0.7em;
color: rgba(204, 204, 204, 0.685);
  
font-family: shortstack;

}



.assets-baking-number {


  font-size: 1.8em;
  color: #FF9C83;

  
  
  font-family: shortstack;

  text-shadow: 1px 1px #282c3449;

  position: relative;

  animation-name: text-focus-in;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  /* shorthand 
  animation: text-focus-in 1s ease 0s 1 normal none;
  */ 
  
  
  }
  
  
  .assets-baking-txt {
  
  
  font-size: 0.7em;
  color: rgba(204, 204, 204, 0.685);
    
  font-family: shortstack;
  
  padding-left: 30%;
  top: -5px;
  
  }
  


.clay-baked-txt {

  font-size: 0.7em;
  color: rgba(204, 204, 204, 0.685);
    
  font-family: shortstack;
  
  padding-left: 25%;
  display: flex;

  


 


}


.total-clay-baked {

  position: relative;
  top: 0px;

}





.token1-png {

  margin-right: 5px;
  width:30px;
  position: relative;
  opacity: 0.9;

}




.baking2-png {


  width:24%;

  position: relative;

top: -8px;
left: 0px;
opacity: 0.9;

  
  }









.baking-png {


width:20px;

position: relative;

top: -5px;

opacity: 0.9;

}

.assets-baking-txt {

display:inline-block;


}




  



  .clay-baking {

    position: relative;
    left: 10px;
    top:-5px;
    display:inline-block;

  }


@keyframes text-focus-in {
  0%{
    filter: blur(12px);
    opacity: 0;
  }
  100%{
    filter: blur(0);
    opacity: 1;
  }
}



  
  

  


  .accounts-txt {


    display:inline-block;

  }



.summary {


width: 90%;

min-height: 100px;
background-color: rgba(255, 255, 255, .15);  
backdrop-filter: blur(5px);

border-radius: 10px;


position: relative;

box-shadow: 0px 0px 30px #b3a6fc2c;

display:flex;




align-items: center;

}







.token-circulation{

position: relative;

width: 320px;
height: 240px;


background-color: rgba(255, 255, 255, 0.1);  
backdrop-filter: blur(5px);

border-radius: 10px;

box-shadow: 0px 0px 30px #4fb9ee28;
z-index: 10;


}



.clay-token {

  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);


  width: 14%;

  opacity: 0.9;

}


.Navigation-links {



  text-align: center;

  display: flex;

  align-items: center;
  justify-content: center;

  margin-top: 20px;

  margin-right: 20px;



  text-transform: capitalize;

}



.Leaderboard-link a {


  color:#52d8a9dc;
  
  text-decoration: none;
  
  text-transform: capitalize; 
  
  letter-spacing: 0.2rem;
  
  margin-right: 20px;


  font-weight: 600;

  font-family: shortstack;

  position: relative;

   cursor: pointer;
    
  
  }



  .Leaderboard-link-selected a {


  color:#52d8a9dc;
  
  text-decoration: none;
  
  text-transform: capitalize; 
  
  letter-spacing: 0.2rem;
  
  margin-right: 20px;


  font-weight: 600;

  font-family: shortstack;

  position: relative;
    
  
  }



  .Leaderboard-link-selected:after {

  position: relative;  
  content: '';
  display: flex;
  position: relative;
  width:135px;
  border-bottom: 2px solid #52d8a9dc;
  margin-left: 10px;

}






.Analytics-link a{
  color: #B3A6FC;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.2rem;
  font-family: shortstack;
  border-bottom-width: 60%;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}



.Analytics-link-selected a{
  color: #B3A6FC;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.2rem;
  font-family: shortstack;
  border-bottom-width: 60%;
  text-decoration: none;
  position: relative;
}



.Analytics-link-selected:after {

  position: relative;  
  content: '';
  display: flex;
  position: relative;
  width:100px;
  border-bottom: 2px solid #B3A6FC;
  margin-left: 8px;

}






.Analytics-table {

    position: relative;
    top: 110px;

    display: flex;
    flex-direction: column;
   
  
    width: 100%;

    min-height: 70vh;

  
    position: relative;
   


    align-items: start;


    background: rgba(67, 89, 111, 1);


    border: 2px solid pink;
    border-image: linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%);
    border-image-slice: 1;

    box-shadow: 0px 0px 24px 3px rgba(191, 205, 202, 0.3) ;


    text-align: center;

    
    overflow: auto; 

   
    backdrop-filter: blur(28px);


}















