


.App3 {
  text-align: center;
  background-image: url("https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/sandbox_storm.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100vh;
  font-family: ShortStack;
  --background:  rgba(0, 0, 0, 0.5);
  --background-hover:  #272e2ed6;
  --clayblue-primary: #4FC8EF;
  --clayblue-secondary: #5870F6;
  --claypurple-primary: #7155A4;
  --claypurple-secondary: #593895;
  --clayyellow-secondary: #FEBD12;
  --claygreen-primary: #B2FE80;
  --claycoral-primary: #DA836F;
  --claytext-white: #F2F2F2;;
  --polygon-purple: #972FCE;
}


.App-header-3 {
  height: 100vh;
  font-family: ShortStack;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.logosvg  {
  filter: brightness(0) invert(1);
  width: 60%;
  opacity: 0.9;
}


.Linkbtn {
  border: none;
  padding: 22px;
  border-radius: 22px;
  
  top: 15%;
  width: 30%;
  padding: 22px;
  cursor: pointer;
  font-size: 1.5rem;
  background: var(--clayblue-secondary);
  color: white;
  position: relative;
  z-index: 1;
  cursor: pointer;
  letter-spacing: 0.1rem;
  text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.5);
  font-family: ButtonFont;

}


.Linkbtn:hover {
  background: linear-gradient(90deg, #2A71D0 0%, #8247E5 100%);
  border: none;
  transition: 0.3s ease;
}



.Polygonbtn {
  background: var(--background);
  color: white;
  outline: 2px solid var(--polygon-purple);
  border: none;
  letter-spacing: 0.1rem;
  text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
  font-family: ButtonFont;
  border-radius: 22px;
  
  width: 260px;
  height: 70px;

  cursor: pointer;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
 
}

.Polygonbtn:hover {
  transition: 0.3s ease;
  background: linear-gradient(90deg, #962fce55 0%, rgba(0, 0, 0, 0.00) 100%);
}



/*
.Polygonbtn {
  width: 300px;
  height: 7vh;
  cursor: pointer;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 22px;
  border-radius: 22px;
  margin-top: 12px;
  font-size: 1.5rem;
  background: var(--background);
  color: rgba(255, 255, 255, 0.7);
  outline: 2px solid var(--polygon-purple);
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 0px black;
  font-family: ShortStack;
}

.Polygonbtn:hover {
  box-shadow: 0px 0px 8px 0px rgba(30, 132, 252, 0.85);
  transition: 0.3s ease;
  background: linear-gradient(90deg, #324949 0%, rgba(0, 0, 0, 0.00) 100%);
}
*/





.CardanoWallets {
  box-sizing: border-box;
  display:flex;
  align-items: center;
  justify-content: center;

}

.walletbtn {
  width: 260px;
  height: 60px;
  cursor: pointer;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 22px;
  border-radius: 22px;
  margin-top: 12px;
  font-size: 1.5rem;
  background: var(--background);
  color: rgba(255, 255, 255, 0.7);
  border: 2px solid var(--clayblue-secondary);
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 0px black;
  font-family: ButtonFont;
}

.walletbtn:hover {
  transition: 0.3s ease;
  background: linear-gradient(90deg, #324949 0%, rgba(0, 0, 0, 0.00) 100%);

}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
  position: relative;
  vertical-align: middle;
  gap: 5rem;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  gap: 1rem;
}