.mint-button-blocked {
  position: absolute;
  bottom: 10%;
  right: 5%;
  transform: translateX(-50%);
  z-index: 100;
  font-family: 'ShortStack';
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 20px 40px;
  border-radius: 25px;
  border: 2px solid #b7ffc1;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)), rgba(151, 218, 247, 0.136);
  color: #fff;
  cursor: pointer;
  
  overflow: hidden; 
  box-shadow: 0 0 0 2px #b7ffc1; 

  user-select: none;
}




.mint-button {

  height: 20px;
  
  z-index: 100;
  font-family: 'ShortStack';
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 20px 40px;
  border-radius: 25px;
  border: 2px solid #b7ffc1;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);
  backdropFilter: blur(6px);

  color: #b7ffc1;
  cursor: pointer;
  transition: ease-in-out all 0.3s;
  overflow: hidden; 
  box-shadow: 0 0 0 2px #b7ffc1; 

  user-select: none;
}

.mint-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.mint-button:focus,
.mint-button:active {
  outline: none;
}

.mint-button:after {
  content: "";
 
  width: 0%;
  background-color: rgba(76, 175, 80, 0.8);
  transition: width 3s ease-in-out;
  z-index: -1;
}

.mint-button:hover:after {
  background-color: rgba(76, 175, 80, 0.8);
}

.mint-button:active:after {
  width: 100%;
}

.mint-button:active:after {
  animation: fill 1.5s ease-in-out forwards;
}

@keyframes fill {
  0% {
    width: 0%;
    left: 0%; /* added to fill from left */
  }
  100% {
    width: 100%;
    left: -2px; /* added to cover the border */
  }
}
