   

    .CN-flip-card {
        background-color: transparent;
        width: 272px;
        height: 384px;
        perspective: 1000px;
        border-radius: 10px;
        text-align: center;

    }


    .CN-flip-card:hover{
        transform: translate(10px);
    }



    .CN-flip-card_clicked {
        background-color: transparent;
        width: 272px;
        height: 384px;
        perspective: 1000px;
        border-radius: 10px;
        text-align: center;

    }



    
    .CN-flip-card-inner {
        position: relative;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        width: 272px;
        height: 384px;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 0 0 22px 7px rgba(255, 181, 181, 0.3); 
    }
    
    .CN-flip-card_hover .CN-flip-card-inner {
        transform: rotateY(180deg);
    }


    .cardLow{
        transition-delay: 0.2s;
    }


    .CN-flip-card_hover .cardLow {
        transition: transform 0s;
        transition-delay: 0.2s;
        transform: rotateY(180deg);
    }



    .CN-flippedCard{

        position: relative;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        width: 272px;
        height: 384px;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 0 0 22px 7px rgba(255, 181, 181, 0.3); 


        transform: rotateY(180deg);

    }



    .CN-flippedCard .cardLow {
        transition: transform 0s;
        transition-delay: 0.2s;
        transform: rotateY(180deg);
    }




    .CN-flip-card-front, .CN-flip-card-back {
        position: absolute;
        text-align: center;
        border-radius: 10px;
        width: 272px;
        height: 384px;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    

    .CN-flip-card-front {
        color: black;
        display: block;
        background-color: transparent;

        text-align: center;
    }
    
    .CN-flip-card-back {
        background-color:transparent;
        color: white;


        border-radius: 10px;

        box-shadow: 0 0 22px 7px rgba(255, 181, 181, 0.9); 


        border: 3px solid #FF9C83;

        transform: rotateY(180deg);

        width: 270px;
        height: 382px;

        text-align: center;

        margin-left: -2px;
        margin-top: -2px;


    }



    .CN-clay-nft {

    position: relative;


    display: block;


    width: 270px;
    height: 270px;

    margin-top: 1px;
    margin-left: 1px;


    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    
    }


    .CN-clay-nft img{

        display:block;

    }



    .CN-clay-id {

    margin-top: 2px;
    position: relative;
    font-family: ShortStack;
    font-size: 1.2rem;
    color:#FF9C83;
    opacity:0.9;

}



.CN-collection-back {


    color:#FF9C83;
    
    font-size: 16px;
    font-family:ShortStack; 


}





.CN-clay-id-back {

  color:#FF9C83;
  font-size: 1rem;
  font-family:ShortStack; 

}



      .CN-clay-nft-back {


        width: 100px;

        border-radius: 6px;

      }





      .CN-clay-properties {

        align-items: left;
        
        font-size: 14px;
                
        display: flex; 
        justify-content: center; 
        text-align: left;

        line-height: 1;

        position: relative;

        left: 0;
        right: 0;
        margin: 0 auto;

    }
        
        
          .CN-clay-percentage {
        
            font-size: 14px;
            display: flex;
            justify-content: center;
            text-align:right;
            color: #FF9C83;
            line-height: 1.2;
            position: relative;
            left: 0;
            right: 0;
            margin: 0 auto;
        
          }


          .CN-properties {

            position: relative;
            font-family: 'ShortStack';

          }
        
        

    
        

          .CN-price-tag-back {

                background-color: transparent;
                border: 1px solid transparent ;
                width: 160px;
                text-align: center;
                letter-spacing: 0.2;
                border-radius: 10px;
                font-size: 1.1rem;
                font-family:ShortStack; 
                padding: 3px;
                display: inline-flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                position: relative;     
                box-sizing: border-box;
                -webkit-tap-highlight-color: transparent;
                outline: none;
                cursor: pointer;

                                
            }





            .CN-price-tag-back-simple {

                background-color: transparent;
                border: 1px solid transparent ;
                width: 160px;
                text-align: center;
                letter-spacing: 0.2;
                border-radius: 10px;
                font-size: 1.1rem;
                font-family:ShortStack; 
                padding: 3px;
                display: inline-flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                position: relative;     
                box-sizing: border-box;
                -webkit-tap-highlight-color: transparent;
                outline: none;
                cursor: pointer;

                                
            }









                          .CN-pricetag {

                            display:block;

                          }


                          .CN-ADA-front {

                            position: absolute;
                            left: -2%;
                            right: 0;
                            margin: 0 auto; 
                            top: 98%;   
                            width: 10%;

                          }

            


                          .CN-ADA-back {

                            z-index: 1;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                          }




                          .CN-amount {

                            position: absolute; 
                            left: 33%;  
                            right: 0;
                            margin: 0 auto; 
                            top: 98%;
                            font-size: 1.1rem;
                            font-family:ShortStack; 
                            opacity: 0.9 ;

                          }


                          .CN-amount-back {

                            color: black;
                            position: absolute; 
                            left: 35%;  
                            right: 0;
                            margin: 0 auto; 
                            top: 90%;
                            opacity: 0.9 ;  
                            z-index: 1;

                          }
            
            

                          .CN-price-back {

                            position: absolute; 
                            left: 0;
                            right: 0;
                            margin: 0 auto; 
                            top: 81%;
                            
                          }
                          


                          .CN-tokens {

                            color: white;
                            opacity: 0.9 ;
                            font-size: 14px;            
                            font-family:ShortStack; 

                          }



                          .CN-price-tag-back:hover .CN-pricetag  {

                            display:none;
                           

                          }


                          .CN-price-tag-back:hover .CN-ADA-back  {
                            
                            display:none;
                            cursor: pointer;

                          }


                          .CN-price-tag-back:hover span {
                            
                            display:none

                          }


                          .CN-price-tag-back:hover img{

                             display:none;

                          }

                          
                          
                          .CN-price-tag-back:hover {

                            display: flex;
                            text-align: center;
                            justify-content: center;
                            align-items: center;

                            background-color: rgba(0, 0, 0, 0.688);
                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            border: 1px solid black;
                            color: white;
                            font-family:ShortStack; 
                            width: 160px;


                          }


                          .BUY-NFT{
                            display: none;
                          }


                          .CN-price-tag-back:hover .BUY-NFT{

                            display: flex;
                            font-size: 1rem;
                            font-family: ShortStack; 
                            justifyContent:'center';
                            width:'160px';

                          }






.CN-SellBtn{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    width: 130px;
    height: 55px;
    border: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgb(242, 138, 128);
    z-index: 3;
    border-radius: 18px;
    position: relative;
    left: 0px;
    box-shadow:  inset 10px 5px 8px rgb(242, 138, 128, 0.3 ), inset -7px -4px 6px rgba(0, 0, 0, 0.3 );
    opacity: 1;   
    cursor: pointer;
   
}


  .CN-SellBtn:before {
    content: '';
    background: linear-gradient(90.09deg, #FDBCAC, rgba(148, 130, 244, 0.950741), #84D3FF , rgba(148, 130, 244, 0.950741) , rgba(246, 141, 141, 0.89) );
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(3px);
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    animation: glowing 10s linear alternate-reverse infinite;
    opacity:0;
    transition: opacity .3s ease-in-out;
    border-radius: 18px;
}



.CN-SellBtn:hover:before {
    opacity: 0;
  
}

.CN-SellBtn:after {
    z-index: -1;
    content: '';
    position: absolute;
    background: rgb(242, 138, 128);
    left: 0;
    top: 0;
    border-radius: 18px;
    overflow: hidden;
    inset: 2px;
    box-shadow:  inset 10px 5px 10px rgb(242, 138, 128), inset -7px -4px 6px rgba(0, 0, 0, 0.3);

}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 300% 0; }
    100% { background-position: 0 0; }
}


.CN-SellBtn:focus::after {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(242, 138, 128, 0.8);
    cursor: pointer;
    z-index: 0;
    border-radius: 18px;
}



.CN-SellBtn:focus {
    box-shadow:  inset -5px -5px 9px rgba(242, 138, 128, 0.8), inset 5px 5px 10px rgba(0, 0, 0, 0.4);
}


.CN-SellBtn:focus::before {
    animation: none;
    display: none;
    text-decoration: none;
}


.CN-SellBtn h1 {
   font-size: 1rem;
   color:bisque;
   text-shadow: 0 0 5px rgba(15, 14, 14, 0.3), 0 2px 1px #457ab254;
}




.buttonFront{
    max-height: 500px;
}

.CN-flip-card:hover .buttonFront {
    max-height: 0;
    transition: max-height 0.6s ease-out;
    overflow: hidden;

}


      