.avatarDisplay {
  display: flex;
  position: absolute;
  left: 20px;
  top: 30px;
  text-align: center;

  z-index: 100;

}

.avatarimg {
  width: 70px;
  min-height: 70px;
  height: auto;
  border-radius: 50px;
  border: 2px solid #B1F2B7;
  filter: drop-shadow(0px 0px 4.04538px #B1F2B7);
  display: inline-block;
  z-index: 1;


  -webkit-user-drag: none; 
  -khtml-user-drag: none; 
  -moz-user-drag: none; 
  -o-user-drag: none; 
  user-drag: none;

  user-select: none;

}


.nicknameBar {
  display: inline-block;
  width: 140px;
  height: 35px;
  border: 2px solid #B1F2B7;
  backdrop-filter: blur(2px);
  border-radius: 20px;
  opacity: 0.9;
  
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto 0;

  word-wrap: break-word;
  transition: ease-in-out all 0.3s; 
  position: relative;
  left: -20px;
  z-index: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);
}


.nicknametext {

  color: rgba(255, 255, 240, 0.744);
  font-family: ShortStack;
  font-size: 1.3rem;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);

  padding: 4px;
  margin-left: 4px;

  user-select: none;

}

