:root {
  --backdrop: hsl(0 0% 60% / 0.12);
  --radius: 14;
  --border: 3;
  --backup-border: var(--backdrop);
  --size: 200;
}

article:first-of-type {
  --base: 80;
  --spread: 500;
  --outer: 1;
}
article:last-of-type {
  --outer: 1;
  --base: 220;
  --spread: 200;
}





/*.pitchesApp *,
.pitchesApp:after *,
.pitchesApp:before *{
  box-sizing: border-box;
}*/





.pitchesApp {
  display: grid;
  place-items: center;
  min-height: 100vh;
  overflow: scroll;
  background: radial-gradient(157.24% 130.63% at 97.77% 97.43%, rgba(199, 54, 101, 0.49) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(111.09% 107.38% at 4.36% 92.22%, rgba(79, 200, 239, 0.49) 0%, rgba(14, 14, 14, 0.12) 100%), linear-gradient(180deg, #634F8B 3.48%, #121C37 99.91%);
}

.wrapper {
  position: relative;
}

article {
  aspect-ratio: 3 / 3;
  border-radius: calc(var(--radius) * 1px);
  width: 360px;
  position: relative;
  grid-template-rows: 1fr auto;
  box-shadow: 0 1rem 2rem -1rem black;
  padding: 1rem;
  display: grid;
  border: 2px solid hsl(0 0% 100% / 0.15);
  backdrop-filter: blur(calc(var(--cardblur, 5) * 1px));

}


 @media (max-width: 500px) {

    .articleCard {
      transform: scale(0.9);
      margin-bottom: -15px;
    }
    
  }


main {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 120ch;
  max-width: calc(100vw - 2rem);
  position: relative;
}





/* Glow specific styles */

[data-glow] {




  /*--border-size: calc(var(--border, 2) * 1px);
  --spotlight-size: calc(var(--size, 150) * 1px);
  --hue: calc(var(--base) + (var(--xp, 0) * var(--spread, 0)));
  background-image: radial-gradient(
    var(--spotlight-size) var(--spotlight-size) at
    calc(var(--x, 0) * 1px)
    calc(var(--y, 0) * 1px),
    hsl(var(--hue, 210) calc(var(--saturation, 100) * 1%) calc(var(--lightness, 70) * 1%) / var(--bg-spot-opacity, 0.1)), transparent
  );*/





  background-color: var(--backdrop, transparent);
  background-size: calc(100% + (2 * var(--border-size))) calc(100% + (2 * var(--border-size)));
  background-position: 50% 50%;
  background-attachment: fixed;
  border: var(--border-size) solid var(--backup-border);
  position: relative;

  background-color: rgba(0,0,40,0.15);

}




















/*[data-glow]::before,
[data-glow]::after {
  pointer-events: none;
  content: "";
  position: absolute;
  inset: calc(var(--border-size) * -1);
  border: var(--border-size) solid transparent;
  border-radius: calc(var(--radius) * 1px);
  background-attachment: fixed;
  background-size: calc(100% + (2 * var(--border-size))) calc(100% + (2 * var(--border-size)));
  background-repeat: no-repeat;
  background-position: 50% 50%;
  mask:
    linear-gradient(transparent, transparent),
    linear-gradient(white, white);
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
}
*/
/* This is the emphasis light */

/*[data-glow]::before {
  background-image: radial-gradient(
    calc(var(--spotlight-size) * 0.75) calc(var(--spotlight-size) * 0.75) at
    calc(var(--x, 0) * 1px)
    calc(var(--y, 0) * 1px),
    hsl(var(--hue, 210) calc(var(--saturation, 100) * 1%) calc(var(--lightness, 50) * 1%) / var(--border-spot-opacity, 1)), transparent 100%
  );
  filter: brightness(2);
}*/
/* This is the spotlight */

/*[data-glow]::after {
  background-image: radial-gradient(
    calc(var(--spotlight-size) * 0.5) calc(var(--spotlight-size) * 0.5) at
    calc(var(--x, 0) * 1px)
    calc(var(--y, 0) * 1px),
    hsl(0 100% 100% / var(--border-light-opacity, 1)), transparent 100%
  );
}
[data-glow] > [data-glow]:not(:is(a, button)) {
  position: absolute;
  inset: 0;
  will-change: filter;
  opacity: var(--outer, 1);
}
[data-glow] > [data-glow]:not(:is(a, button)) {
  border-radius: calc(var(--radius) * 1px);
  border-width: calc(var(--border-size) * 20);
  filter: blur(calc(var(--border-size) * 10));
  background: none;
  pointer-events: none;
}
[data-glow] > [data-glow]:not(:is(a, button))::before {
  inset: -10px;
  border-width: 10px;
}
[data-glow] > [data-glow] {
  border: none;
}
[data-glow] :is(a, button) {
  border-radius: calc(var(--radius) * 1px);
  border: var(--border-size) solid transparent;
}
[data-glow] :is(a, button) [data-glow] {
  background: none;
}
[data-glow] :is(a, button) [data-glow]::before {
  inset: calc(var(--border-size) * -1);
  border-width: calc(var(--border-size) * 1);
}*/

/*article button {
  padding: 0.75rem 2rem;
  align-self: end;
  color: hsl(0 0% 80%);
}

button[data-glow] span {
  font-weight: bold;
  background-image: radial-gradient(
    var(--spotlight-size) var(--spotlight-size) at
    calc(var(--x, 0) * 1px)
    calc(var(--y, 0) * 1px),
    hsl(var(--hue, 210) calc(var(--saturation, 100) * 1%) calc(var(--lightness, 70) * 1%) / var(--bg-spot-opacity, 1)), transparent
  );
  background-color: var(--backdrop, transparent);
  background-position: 50% 50%;
  background-attachment: fixed;
  background-clip: text;
  filter: brightness(1.5);
  color: transparent;
}*/