

.cartImg {
    width:100px;
    height: 100px;
    border-radius: 0px;
   
    display: inline-block;
    z-index: 100;

    -webkit-user-drag: none; 
    -khtml-user-drag: none; 
    -moz-user-drag: none; 
    -o-user-drag: none; 
    user-drag: none;

    user-select: none;
  
  }


  
.itemsNumber {
    position: absolute;
    font-size: 35px;
    user-select: none;
    font-family: ShortStack;
    margin-top: 0px;
    margin-left: -5px;
    color: #B1F2B7;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
    z-index: 101;
}


