







.CLAY-share {


  color: rgba(255, 255, 255, 0.846);
  font-size: 0.6rem;
  
  
  
  position: relative;
  
  top: 22px;
  
  padding:2px;
  
  padding-left:2px;
  
  
  
  
  
  
  }
  
  
  .CLAY-share-percentage {
  
  
    color: rgba(255, 255, 255);
  
    font-size: 0.9rem;
    
    padding:2px;
  
  
  top: 15px;
  
  position: relative;
  
  
  text-shadow: 2px 2px rgba(0, 0, 0, 0.194);
  
  
  
  }








.user-share-percentages-3 {


  width: 80px;
  height: 80px;
  
    
  border-radius: 10px;
  
  line-height: 0.8;
  
  border: 1px solid #fac424a9;
  border-radius: 15px;
  
  
  color: white;
  
  box-shadow: 0px 0px 10px 10px rgba(7, 5, 50, 0.144);


  display: block;


	-webkit-animation: scale-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;  
  
  
  
  }
  























.CLAY-share {


  color: rgba(255, 255, 255, 0.846);
  font-size: 0.6rem;
  
  
  
  position: relative;
  
  top: 22px;
  
  padding:2px;
  
  padding-left:2px;
  
  
  
  
  
  
  }
  
  
  .CLAY-share-percentage {
  
  
    color: rgba(255, 255, 255);
  
    font-size: 0.9rem;
    
    padding:2px;
  
  
  top: 15px;
  
  position: relative;
  
  
  text-shadow: 2px 2px rgba(0, 0, 0, 0.194);
  
  
  
  }








.user-share-percentages-2 {


  width: 80px;
  height: 80px;
  
  
  border-radius: 10px;
  
  line-height: 0.8;
  
  border: 1px solid #fac424a9;
  border-radius: 15px;
  
  
  color: white;
  
  box-shadow: 0px 0px 10px 10px rgba(7, 5, 50, 0.144);


  display: block;


	-webkit-animation: scale-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;  
  
  }
  


  @-webkit-keyframes scale-in {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  










  


.unbaked-received-number {




  display: block;
    
    color: white;
    
    font-size: 1.3rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.194);


    line-height: 1.2;



animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2.4s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2.4s both;

}











.CLAY-received-number {




  display: block;
    
    color: white;
    
    font-size: 1.3rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.194);


    line-height: 1.2;



animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2.1s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2.1s both;

}









.times-claimed-number {




  display: block;
    
    color: white;
    
    font-size: 1.3rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.194);


    line-height: 1.2;



animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 1.8s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 1.8s both;

}








  .ada-received-number {


    display: block;
    
    color: white;
    
    font-size: 1.3rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.194);



animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 1.5s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 1.5s both;
    
    }
    
    


  








.share-unbaked-CLAY {
  




top: 10px;







}






.ADA-share {


color: rgba(255, 255, 255, 0.846);
font-size: 0.6rem;



position: relative;

top: 22px;

padding:2px;

padding-left:2px;






}


.ADA-share-percentage {


  color: rgba(255, 255, 255);

  font-size: 0.9rem;
  
  padding:2px;


top: 15px;

position: relative;


text-shadow: 2px 2px rgba(0, 0, 0, 0.194);



}








.user-share-percentages-1 {


  width: 80px;
  height: 80px;
  
  
  border-radius: 10px;
  
  line-height: 0.8;
  
  border: 1px solid #fac424a9;
  border-radius: 15px;
  
  
  color: white;
  
  box-shadow: 0px 0px 10px 10px rgba(7, 5, 50, 0.144);


  display: block;



	-webkit-animation: scale-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;  
  
  
  
  }
  
  
  
  
  .user-share-percentages-1:hover {
  
   cursor: pointer;  
    box-shadow: 0px 0px 10px 2px #fac4243d;
  
  
  }

  .user-share-percentages-2:hover {
  
    cursor: pointer;  
     box-shadow: 0px 0px 10px 2px #fac4243d;
   
   
   }

   .user-share-percentages-3:hover {
  
    cursor: pointer;  
     box-shadow: 0px 0px 10px 2px #fac4243d;
   
   
   }
  











  












.rewards-received-tag {
  
  align-items: center;
  text-align: center;
  
  color: white;

  
  background-color: rgba(19, 18, 38, 0.582);  
  backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  
  border-radius: 10px;
  
  padding: 8px;
  
  
  box-shadow: 0px 0px 7px 7px rgba(7, 5, 50, 0.052);
  
  font-size: 0.7rem;




  -webkit-animation-name: slidefromRight;
  animation-name: slidefromRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;




  
  }
  
  


  @-webkit-keyframes slidefromRight {
    0% {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    }
    }
    @keyframes slidefromRight {
    0% {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    }
    } 
  














  .ADA-rewards-total-received {


  display: block;
  text-align: center;

  background-color: rgba(35, 35, 68, 0.516);  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;

  

padding-top:10px;
padding-bottom: 10px;
padding-left: 25px;
padding-right: 25px;


box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.265);



}























.staked-with-pool {

  
  align-items: center;
  text-align: center;
  
  color: white;

  
  background-color: rgba(19, 18, 38, 0.582);  
  backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  
  border-radius: 10px;
  
  padding: 8px;
  
  
  box-shadow: 0px 0px 7px 7px rgba(7, 5, 50, 0.052);
  
  font-size: 0.7rem;


  -webkit-animation-name: slidefromLeft;
  animation-name: slidefromLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
 


  
  }
  
  


  @-webkit-keyframes slidefromLeft {
    0% {
    -webkit-transform: translateX(-80%);
    transform: translateX(-80%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    }
    }
    @keyframes slidefromLeft {
    0% {
    -webkit-transform: translateX(-80%);
    transform: translateX(-80%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    }
    } 







.times-claimed {


  display: block;
  text-align: center;

  background-color: rgba(35, 35, 68, 0.416);  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;

  

padding-top: 7px;
padding-bottom: 7px;
padding-left: 10px;
padding-right: 10px;


box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.265);



}




.user-times-txt {


color: white;
font-size: 0.6rem;




}






.CLAY-rewards-received {


  display: block;
  text-align: center;


  background-color: rgba(35, 35, 68, 0.516);  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;

padding-top: 10px;
padding-bottom: 10px;
padding-left: 18px;
padding-right: 18px;


box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.265);



}

























.unbaked-rewards-received {


  display: block;
  text-align: center;

  background-color: rgba(35, 35, 68, 0.416);  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;

padding-top: 10px;
padding-bottom: 10px;
padding-left: 18px;
padding-right: 18px;

box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.265);




}


.user-CLAY-number {


display: block;

color: white;

font-size: 1.1rem;
text-shadow: 2px 2px rgba(0, 0, 0, 0.194);

}

.rewards-CLAY-unbaked-txt {


color: rgba(255, 255, 255, 0.799);

display: block;

font-size: 0.6rem;

}


.epochs-txt-stk {


  color: rgba(255, 255, 255, 0.653);
  
  display: block;
  
  font-size: 0.5rem;
  
  }



















.unbaked-CLAY-staked{


  display: block;
  text-align: center;


  background-color: rgba(35, 35, 68, 0.416);  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;


  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;


box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.265);



}


.unbaked-CLAY-number {


display: block;

color: white;

font-size: 1.3rem;
text-shadow: 2px 2px rgba(0, 0, 0, 0.194);


animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 0.6s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 0.6s both;

}

.unbaked-CLAY-txt {


color: rgba(255, 255, 255, 0.799);

display: block;

font-size: 0.6rem;

}


.epochs-txt-stk {


  color: rgba(255, 255, 255, 0.653);
  
  display: block;
  
  font-size: 0.5rem;
  
  }














.baked-CLAY-staked {


  display: block;
  text-align: center;




  background-color: rgba(35, 35, 68, 0.516);  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;


  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;



box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.265);



}


.baked-CLAY-number {


display: block;

color: white;

font-size: 1.3rem;
text-shadow: 2px 2px rgba(0, 0, 0, 0.194);


animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 0.3s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 0.3s both;

}



@-webkit-keyframes text-appear {
  0% {
    -webkit-filter: blur(13px);
            filter: blur(13px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-appear {
  0% {
    -webkit-filter: blur(13px);
            filter: blur(13px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}





.baked-CLAY-txt {


color: rgba(255, 255, 255, 0.799);

display: block;

font-size: 0.7rem;

}


.user-CLAY-txt {


  color: rgba(255, 255, 255, 0.799);
  
  display: block;
  
  font-size: 0.7rem;
  
  }


.epochs-txt-stk {


  color: rgba(255, 255, 255, 0.653);
  
  display: block;
  
  font-size: 0.5rem;
  
  }


















.ADA-staked {


  display: block;
  text-align: center;



  background-color: rgba(35, 35, 68, 0.516);  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;


box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.265);



}


.ada-number {


display: block;

color: white;

font-size: 1.3rem;
text-shadow: 2px 2px rgba(0, 0, 0, 0.194);


animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 0.9s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 0.9s both;

}

.ada-stk-txt {


color: rgba(255, 255, 255, 0.799);

display: block;

font-size: 0.7rem;

}


.epochs-txt-stk {


  color: rgba(255, 255, 255, 0.653);
  
  display: block;
  
  font-size: 0.5rem;
  
  }












.epochs-staked {


  display: block;
  text-align: center;

  background-color: rgba(35, 35, 68, 0.416);  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;

padding-top: 10px;
padding-bottom: 10px;
padding-left: 27px;
padding-right: 27px;



box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.265);



}


.epochs-number {


display: block;

color: white;

font-size: 1.3rem;
text-shadow: 2px 2px rgba(0, 0, 0, 0.194);


animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 1.2s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 1.2s both;

}

.epochs-txt {


color: rgba(255, 255, 255, 0.799);

display: block;

font-size: 0.7rem;

}


.epochs-txt-stk {


  color: rgba(255, 255, 255, 0.653);
  
  display: block;
  
  font-size: 0.5rem;
  
  }




.ada-symbol {




position: absolute;
top: 52px;


  left: 400px;

  width: 20px;





}



.total-ada-rewards {



  color: black;
  opacity:0.7;


  position:absolute;

  font-size: 1.2rem;


  top: 50px;


  left: 423px;


}








.rewards-txt {



position: relative;

COLOR: black;


font-size: 1.4rem;

left: -45%;
top: -50%;

opacity: 0.8;


}






.rewardws-png {



width: 25%;


margin: auto;

position: relative;

top: -43px;
left: 47px;

opacity: 1;







}

@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}
@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}





.total-CLAY-staked {


  position: absolute;


  top: 65px;
  left: 125px;
  
  z-index: 1;

  display: block;
  

  
  align-items: center;
  text-align: center;
  
  background-color: rgba(45, 56, 75, 0.217);  

  



  backdrop-filter: blur(5px);
  
  border-radius: 10px;
  
  padding: 8px;
  
  
  
  }
  
  
  
  .total-staked {
  
  
    display: block;
  
    font-size: 0.7rem;
  
  color: rgba(255, 255, 255, 0.857);
  
  }
  

    









.staking-info {


min-width: 300px;
height: 240px;


border: 1px solid #84d4ff9c;
border-radius: 15px;

box-shadow: 0px 0px 10px 10px rgba(7, 5, 50, 0.144);


}



.staking-info:hover {

  cursor: pointer;  
   box-shadow: 0px 0px 10px 2px #3c83a99c;
 
 
 }
 









.clay-token {


width: 25px;

position: relative;
float: left;

top:2px;


}



.CLAYTOKEN {


width: 75px;

z-index: 1;


-webkit-animation-name: TokenDown;
animation-name: TokenDown;
-webkit-animation-duration: 1.3s;
animation-duration: 1.3s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
animation-delay: 3s;





}



@-webkit-keyframes TokenDown {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -3000px, 0);
  transform: translate3d(0, -3000px, 0);
  }
  
  60% {
  opacity: 1;
  -webkit-transform: translate3d(0, 25px, 0);
  transform: translate3d(0, 25px, 0);
  }
  
  75% {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  }
  
  90% {
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  }
  
  100% {
  -webkit-transform: none;
  transform: none;
  }
  }
  
  @keyframes TokenDown {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -3000px, 0);
  transform: translate3d(0, -3000px, 0);
  }
  
  60% {
  opacity: 1;
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  }
  
  75% {
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  }
  
  90% {
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  }
  
  100% {
  -webkit-transform: none;
  transform: none;
  }
  } 








.total-CLAY-rewards:hover {

  cursor: pointer;  
  box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.665), 0px 0px 8px 2px rgba(140, 188, 242, 0.792);

}






.total-CLAY-rewards {


color: white;


text-shadow: 2px 2px rgba(0, 0, 0, 0.194);

border:2px solid rgba(140, 188, 242, 0.892);


background-color: rgba(35, 35, 68, 0.416);  

box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.665);

padding-top:10px;
padding-bottom:10px;

padding-left:20px;
padding-right:20px;

border-radius: 10px;

font-size: 1.5rem;
letter-spacing: 0.3rem;

opacity: 0.8;




animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2.8s both;

-webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2.8s both; 


}






@keyframes text-focus-in {
			
  0% {
    filter:blur(12px);
    opacity:0;
  }
  100% {
    filter:blur(0);
    opacity:1;
  }
}









.claim-staking-btn:hover  {
  box-shadow:  0px 0px 11px rgba(140, 188, 242, 1.792) ;
}


.claim-staking-btn {
  width: 100px;
  height: 50px;
  margin: 8px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  padding: 2px;
  padding-left: 20px;
  padding-right: 20px;  
  box-shadow:  inset 10px 5px 8px rgba(117, 151, 191, 0.3 ), inset -7px -4px 6px rgba(0, 0, 0, 0.3 );
  opacity: 1;
  border: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;    
  background: rgba(140, 188, 242, 0.792);
  color: white;
  cursor: pointer;  
  z-index: 0;
  border-radius: 18px;
  font-family: ShortStack;
  font-size: 1.0rem;
  letter-spacing: 0.1rem;
  animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 3s both;
  -webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 3s both; 
}





.claim-staking-btn-off {


  width: 100px;
  height: 50px;
  margin: 8px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  padding: 2px;
  padding-left: 20px;
  padding-right: 20px;  
  box-shadow:  inset 10px 5px 8px rgba(117, 151, 191, 0.3 ), inset -7px -4px 6px rgba(0, 0, 0, 0.3 );
  opacity: 1;
  border: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;    
  background: rgba(140, 188, 242, 0.792);
  color: white; 
  z-index: 0;
  border-radius: 18px;
  font-family: ShortStack;
  font-size: 1.0rem;
  letter-spacing: 0.1rem;
  animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 3s both;
  -webkit-animation: text-appear 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 3s both; 


  filter:opacity(0.2);
  cursor: default;
}







.epoch {


position: absolute;

display: block;

top:-40%;

left: 44.5%;

align-items: center;
text-align: center;



background-color: rgba(255, 255, 255, 0.097);  
backdrop-filter: blur(5px);

border-radius: 10px;

padding: 8px;


box-shadow: 0px 0px 7px 7px rgba(7, 5, 50, 0.052);



}



.current-epoch {


  display: block;

  font-size: 0.9rem;

color: rgba(255, 255, 255, 0.857);

}



.current-epoch-days-left {

  
  display: block;

  font-size: 0.5rem;

color: rgba(255, 255, 255, 0.857);

}



.user-data {
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
  border-radius: 10px;
 


  display: flex;
  justify-content: center;
}



.rewards-tag {



  z-index: 1;

  
  min-height: 20px;
  position: relative;
  top: 5px;

  align-items: center;
  text-align: center;
  background-color: rgba(45, 56, 75, 0.317);  

  backdrop-filter: blur(5px);
  
  border-radius: 10px;
  
  padding: 8px;
  
  
  
  }





.rewards {


  width: 300px;
  min-width: 300px;
  height: 240px;
  border-radius: 10px;
  border: 1px solid #f68d8d74;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 10px rgba(7, 5, 50, 0.144);
}




.rewards:hover {

 cursor: pointer;  
  box-shadow: 0px 0px 10px 2px #f68d8d74;


}



.clay-pool {

  position: absolute;
  top:9.5%;
  left: 47.5%;
  transform: translateX(-50%);

  color: #ccc;


  font-size: 1.3rem;
  letter-spacing: 0rem;


  /*
  text-shadow: 2px 2px #ffd500;
*/


}





.delegations-txt {

  margin-top: 5px;

  color: #ccc;

  font-size: 0.5rem;
  letter-spacing: 0rem;

}



.delegations{


  font-size: 0.8rem;

  color: rgb(230, 226, 226);


}



.clay-pool-img {


width: 100px;

opacity: 0.9;


position: relative;

left: 0px;
top: 30px;


filter: grayscale(10%);



}


.pool-margin-txt {

  margin-top: 5px;


  color: #ccc;


  font-size: 0.5rem;
  letter-spacing: 0rem;



}



.pool-margin {





  font-size: 0.8rem;

  color: rgb(225, 221, 221);


}






















.pool-pledge-txt {


  color: rgba(0, 0, 0, 0.67);

  margin-top: 5px;


  font-size: 0.5rem;
  letter-spacing: 0rem;

  color: #ccc;

}



.pool-pledge {

  color: rgb(229, 228, 228);
  font-size: 0.8rem;

}


.pool-info-text{

color: #ccc;
font-size: 1.2rem;
letter-spacing: 0rem;
}



.ADA-delegated {

  margin-top: 5px;


color: #ccc;
font-size: 1.2rem;
letter-spacing: 0rem;

/*
text-shadow: 2px 2px #ffd500;
*/
}


.staking-stats {


display: BLOCK;


}



/*
.sun-png {
  height: 45vmin;
  pointer-events: none;

  transform: rotate(deg);

  position: absolute; 

  top:-60px;
 left: 25%;
  transform: translateX(-50%); 



}

@media (prefers-reduced-motion: no-preference) {
  .sun-png {
    animation: App-logo-spin infinite 200s linear;
  }
}


.beams-png {
  height: 45vmin;
  pointer-events: none;

  transform: rotate(deg);

  position: absolute;

  top:-7px;
 left: 24.5%;
  transform: translateX(-50%); 



}
*/



.staking {


  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 620px;

  max-width: 1200px;



  
  border: 2px solid pink;
      border-image: linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%);
      border-image-slice: 1;
  
   
      background: radial-gradient(ellipse at center, rgba(161, 180, 219, 0.554), rgba(17, 24, 74, 0.565));

  
  backdrop-filter: blur(33px);
  
    
  
  }
  


  
  
  
  .staking-pool {

    width: 220px;
    height: 220px;


    position: relative;

    top: -45px;
 

    background-color: #292c40;  

    box-shadow: inset 0px 0px 30px 5px rgba(29, 29, 35, 0.665);

    backdrop-filter: blur(5px);
    
    border-radius: 50%;


    -webkit-animation-name:  zoomPool;
    animation-name:  zoomPool;
    -webkit-animation-duration: 1.7s;
    animation-duration: 1.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;



/*
    box-shadow: 0 0 10px 1px #f4e79f32;
    */
  
padding: 1px;

   

  }


  

  @-webkit-keyframes zoomPool {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    }
    @keyframes zoomPool {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    } 
  
  
  
  

  
  
  
  .App-link {
    color: #61dafb;
  }
  
  
  
  



