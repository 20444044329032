.totalTokens {
    min-width: 10%;
    max-width: 240px;
    height: 65px;
    background: rgba(217, 217, 217, 0.18);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    position:relative;
}


.totalTokens2 {
    min-width: 10%;
    max-width: 240px;
    height: 100%;
    background: rgba(217, 217, 217, 0.18);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    position:relative;
    cursor: pointer;

    border: 3px solid rgba(255,255,255,0);
}


.totalTokens2:hover {
    min-width: 10%;
    max-width: 240px;
    height: 100%;
    background: rgba(217, 217, 217, 0.18);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    position:relative;
    cursor: pointer;

    border: 3px solid rgba(44, 179, 255, 0.8)
}












.totalNumber {
    color: rgba(44, 179, 255, 1);
    font-size: calc(0.6em + 1.8vh);
    font-weight: 600;
    text-shadow: 0px 0px 2px rgba(44, 179, 255, 0.4);
}

.totalText {
    color: rgba(44, 179, 255, 0.8);
    font-size: calc(0.3em + 0.9vh);
}





.tokens {
    min-width: 10%;
    max-width: 220px;
    height: 65px;
    background: rgba(217, 217, 217, 0.15);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center; 
    cursor: pointer; 
}


.tokens2 {
    min-width: 10%;
    max-width: 220px;
    height: 100%;
    background: rgba(217, 217, 217, 0.15);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center; 
    cursor: pointer; 

    border: 3px solid rgba(255,255,255,0);
}


.tokens2:hover {
    min-width: 10%;
    max-width: 220px;
    height: 100%;
    background: rgba(217, 217, 217, 0.15);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center; 
    cursor: pointer; 

    border: 3px solid rgba(196, 185, 255, 0.8)
}


.tokensText {
    color: rgba(196, 185, 255, 0.8);
    font-size: calc(0.3em + 0.9vh);
}


.tokensNumber {
    color: rgba(196, 185, 255, 1);
    font-size: calc(0.6em + 1.8vh);
    font-weight: 600;
    text-shadow: 0px 0px 2px  rgba(196, 185, 255, 0.5);
}




.assets {
    min-width: 10%;
    max-width: 180px;
    height: 60px;
    background: rgba(217, 217, 217, 0.11);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    padding-left: 0.2em;
    padding-right: 0.2m;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.assets2 {
    min-width: 10%;
    max-width: 180px;
    height: 100%;
    background: rgba(217, 217, 217, 0.11);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    padding-left: 0.2em;
    padding-right: 0.2m;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border: 3px solid rgba(255,255,255,0);
}


.assets2:hover {
    min-width: 10%;
    max-width: 180px;
    height: 100%;
    background: rgba(217, 217, 217, 0.11);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    padding-left: 0.2em;
    padding-right: 0.2m;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border: 3px solid rgba(255, 201, 188, 0.8);
}



.assetsText {
    color: rgba(255, 201, 188, 0.7);
    font-size: calc(0.3em + 0.9vh);
    padding-right: 3px;
}


.assetsNumber {
    color: rgba(255, 201, 188, 1);
    font-size: calc(0.6em + 1.8vh);
    font-weight: 600;
    text-shadow: 0px 0px 2px  rgba(255, 201, 188, 0.5);
}
