
.classicBtn{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    width: 120px;
    height: 55px;
    border: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(117, 151, 191);
    cursor: pointer;
    z-index: 3;
    border-radius: 18px;
    position: relative;
    left: 0px;
    box-shadow:  inset 10px 5px 8px rgba(117, 151, 191, 0.3 ), inset -7px -4px 6px rgba(0, 0, 0, 0.3 );
    opacity: 1;   
    cursor: pointer;
}



.classicBtn:active{
    transform: translateY(2px);
}















.claimBtn{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    width: 120px;
    height: 55px;
    border: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(117, 151, 191);
    cursor: pointer;
    z-index: 3;
    border-radius: 18px;
    position: relative;
    left: 0px;
    box-shadow:  inset 10px 5px 8px rgba(117, 151, 191, 0.3 ), inset -7px -4px 6px rgba(0, 0, 0, 0.3 );
    opacity: 1;   
    cursor: pointer;
   
}

  .claimBtn:before {
    content: '';
    background: linear-gradient(90.09deg, #FDBCAC, rgba(148, 130, 244, 0.950741), #84D3FF , rgba(148, 130, 244, 0.950741) , rgba(246, 141, 141, 0.89) );
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(3px);
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    animation: glowing 10s linear alternate-reverse infinite;
    opacity:0;
    transition: opacity .3s ease-in-out;
    border-radius: 18px;
}



.claimBtn:hover:before {
    opacity: 0.8;
  
}

.claimBtn:after {
    z-index: -1;
    content: '';
    position: absolute;
    background: rgba(117, 151, 191);
    left: 0;
    top: 0;
    border-radius: 18px;
    overflow: hidden;
    inset: 2px;
    box-shadow:  inset 10px 5px 10px rgba(117, 151, 191, 1), inset -7px -4px 6px rgba(0, 0, 0, 0.3);

}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 300% 0; }
    100% { background-position: 0 0; }
}


.claimBtn:focus::after {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(117, 151, 191, 0.8);
    cursor: pointer;
    z-index: 0;
    border-radius: 18px;
}



.claimBtn:focus {
    box-shadow:  inset -5px -5px 9px rgba(117, 151, 191, 0.8), inset 5px 5px 10px rgba(0, 0, 0, 0.4);
}


.claimBtn:focus::before {
    animation: none;
    display: none;
    text-decoration: none;
}


.claimBtn h1 {
   font-size: 0.9rem;
   color:bisque;
   text-shadow: 0 0 5px rgba(15, 14, 14, 0.3), 0 2px 1px #457ab254;
}







:root {
--toastify-color-info: #64a3b8;
  --toastify-color-success: #7dab4f;
  --toastify-color-warning: #cba607;
  --toastify-color-error: #e25547;
}




