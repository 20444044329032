.inputField {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    width: 400px;
    height: 55px;
    outline: none;
    border: 20px;
    background: rgba(0, 0, 0, 0.14);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    backdrop-filter: blur(71px);
    border-radius: 17px;
    text-align: start;
}  




.inputField:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(146, 204, 236);
    opacity: 0.7;
    border-radius: 17px;
    transition: all 0.5s ease;
}


.inputField:after{
    content: '';
    position: absolute;
    inset: 2px;
    background: rgba(67, 90, 113, 1);
    box-shadow: inset 5px 6px 4px rgba(0, 0, 0, 0.25);    
    border-radius: 17px;
    transition: all 0.5s ease;
}



.inputField input {
  width: 65%;
  height: 55px;
  position: relative;
  padding: 0 16px;
  border: none;
  border-radius: 17px;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  background: transparent;
  background-color: transparent;
  color: bisque;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 200;
}


.inputField input::placeholder  { 
  z-index: 210;
  position: relative;
  color: bisque;
  font-size:  22px;
  letter-spacing: 0.2em;
}


