.leftArrow {

  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  left: 22px;
  font-size: 52px;
  color: rgb(89, 242, 128,0.5);

  z-index: 1;
  cursor: pointer;

}

.leftArrow:hover {



  color: rgb(89, 242, 128, 1);
  
  z-index: 1;
  cursor: pointer;
  
  }


  .rightArrow {

    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    right: 22px;
    font-size: 52px;
    color: rgb(89, 242, 128,0.5);
    
    z-index: 1;
    cursor: pointer;

     



    
    }



.rightArrow:hover {



  color: rgb(89, 242, 128, 1);
  
  z-index: 1;
  cursor: pointer;
  
  }


  .updatesSlide {

    width: 100%;
    height: 100%;
    border-radius: 22px;
    background-position: center;
    background-size: cover;
 
    animation: fadeIn 4s;


    

  }


  .carouselwrapper {

    animation: fadeIn 1s;
    height: 100%;
position: relative;
color: rgb(89, 242, 128, 1);

  }

.dots {


  margin: 0 3px;
  cursor: pointer;
  font-size: 20px;
  opacity: 0.5;

  animation: fadeIn 6s;
}

.dots:hover {

opacity: 1;

}

.button0 {

  font-size: 0.5rem;
  font-family: ShortStack;

  padding: 10px;


  z-index: 100;
  cursor: pointer;

  color: rgba(160, 255, 221, 0.52);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.18) 0%, rgba(20, 237, 224, 0.1332) 100%);

  border-radius: 11px;

  background-blend-mode: overlay;
  border: 1px solid rgba(134, 226, 208, 0.39);

  box-shadow: inset 2px 4px 5px rgba(255, 255, 255, 0.06), inset 0px -4px 6px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4.5px);

  filter: drop-shadow(0px 0px 5px rgba(134, 226, 208, 0.61));


  position: relative;
  top:190px;
  left:-100px;

}


  .button0:hover {

    border: 1px solid rgba(134, 226, 208, 0.79);
    filter: drop-shadow(0px 0px 4px rgba(134, 226, 208, 0.91));


  }


  .button1 {

    font-size: 0.5rem;
    font-family: ShortStack;
    
    padding: 10px 20px;
    
    
    z-index: 100;
    cursor: pointer;
    
    color: rgba(255, 255, 255, 0.92);    

    text-shadow: 0px 0px 7px rgba(255, 254, 235, 0.46);
    letter-spacing: 0.3em;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.18) 0%, rgba(20, 237, 224, 0.1332) 100%);    
    border-radius: 11px;
    
    background-blend-mode: overlay;
    border: 2px solid rgba(134, 208, 226, 0.59);
    
    box-shadow: inset 2px 4px 5px rgba(255, 255, 255, 0.06), inset 0px -4px 6px rgba(0, 0, 0, 0.25);    backdrop-filter: blur(4.5px);
    
    filter: drop-shadow(0px 0px 5px rgba(134, 226, 208, 0.61));
    
    
    position: relative;
    top:230px;




    
      }

      .button1:hover {

        border: 1px solid rgba(134, 208, 226, 0.79);
        filter: drop-shadow(0px 0px 4px rgba(134, 208, 226, 0.9));
    
    
      }
    



      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }




