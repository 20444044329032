
  .SelectionPanel {


    /*background-color: rgba(0, 0, 0, 0.221);*/
    background: linear-gradient(180deg, rgba(227, 227, 227, 0.16) 0%, rgba(217, 217, 217, 0) 100%);
    width: 420px;
    height: 70%;

    position: absolute;

    border-radius: 15px;

    backdrop-filter: blur(5px);

    align-items: center;
    justify-content: center;

    box-shadow: inset 1px 8px 10px rgba(255, 255, 255, 0.04);

    overflow: scroll;

    width: 90%;
    min-width: 250px;
    margin-left: 50px;
    margin-top: 60px;

  }


  .Grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); 
    grid-gap: 10px;
  }


  .Inner{
     margin-left: 45px;
     margin-right: 10px;
     margin-bottom: 40px;
  }



  .TabsUI {
  position: absolute; 
  left: -650px;
  top: -220px;
}


.avatarIcon {
  width: auto;
  height: 70px;
}

.trousersIcon {

  height: 55px;
  width: auto;

}

.sweatshirtIcon {
  height: 55px;
  width: auto;
  opacity: 0.9;

}