.here {

margin-top: 15px;
width: 80px;
text-transform: lowercase;

padding: 2px;

background-color: rgba(0, 0, 0, 0.913);
color: bisque;

border-radius: 7px;


    justify-content: center;



    position: relative;

        left: 5px;

    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    outline: 0px;

    cursor: pointer;

    border: 18px;

  flex: 1;

    
    }


    .view-here {

     display: flex;

    

        text-align:center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
    
        float: center; 
    
    

        margin: 0;

        color: bisque;

        font-size: 18px;

    }



    .here:hover {


        box-shadow: 3px 3px 3px rgba(14, 14, 14, 0.3);
    }