.placeholderNoassets {

  align-items: center;

  width: 100%;
  height: 500px;

  position: relative;


  background: rgba(67, 89, 111, 0.109);



  background: rgba(67, 89, 111, 0.3);


  border: 2px solid pink;
  border-image: linear-gradient(90deg, rgb(146, 204, 236, 0.5) 0%, rgba(148,130,244,0.5) 49%, rgba(246,141,141,0.5) 80%);
  border-image-slice: 1;
      
  backdrop-filter: blur(20px);



}



.noAssets {
  font-size: 28px;
  letter-spacing: 1rem;
  text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.5);
  color: rgba(255, 228, 196, 0.832);  
}





.getThem {
  font-size: 22px;
  letter-spacing: 0.4rem;
  text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  color: rgba(255, 228, 196, 0.832);  
  }



.jpgstoreBtn {

    width: 160px;
    height: 65px;

    background: rgba(217, 217, 217, 0.49);

    border-radius: 18px;

   
     display: inline-flex;
     -webkit-box-align: center;
     align-items: center;
     -webkit-box-pack: center;
     justify-content: center;

     box-sizing: border-box;
     -webkit-tap-highlight-color: transparent;

     outline: none;
     border: 18px;
     cursor: pointer;

  }



  .btnTxt {
    font-size: 16px;    
  }

  .jpgLogo {
    width: 14%;
  }



  .jpgstoreBtn:hover {
    box-shadow: 3px 4px 11px rgba(0, 0, 0, 0.4);
  }


  .jpgstoreBtn:active{
    transform: translateY(2px);
  }