.Map-App {
  background-color: #699196;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.Map{
  position: relative;
  width: 100%;
  height: 100%;
}

#canvas{
  position: absolute;
}