.plot-card{


    width: 80%; 
    height: 80%;
    max-height:400px;
    max-width:280px;

    border-radius: 20px;
    position: absolute; 
    z-index: 5000; 
    
}


.plot-card::before{
	content: "";

	 background-image: linear-gradient(180deg, rgba(69, 62, 112, 0.95) 0%, #1D3A3D 100%), 
                    linear-gradient(rgba(239, 132, 99, 0.78) 4.03%, #CCA7B5 44.47%, rgba(96, 157, 183, 0.82) 100%); 

      border-radius: 20px;
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.8;
}


