

.form-container {
  width: 60vw;
  height: 75vh;
  border-radius: 40px;
  display: flex;
  position: relative;

  min-height: 600px;
}



.form-container h1 {
  font-family: ButtonFont;
  color: WHITE;
  letter-spacing: 0.13em;
  text-shadow: -3px 0px 0px rgba(0, 0, 0, 0.18);
  font-size: calc(10px + 3vmin);

  margin-top: 7%;
  margin-bottom: 4%;
  opacity: 1;

  text-align: center;
  justify-content: center;
  align-items: center;


}


.raffle-pannel h1 {
  font-family: ButtonFont;
  color: WHITE;
  letter-spacing: 0.13em;
  text-shadow: -3px 0px 0px rgba(0, 0, 0, 0.18);
  font-size: calc(10px + 3vmin);

  margin-top: 7%;
  margin-bottom: 4%;
  opacity: 1;

  margin-left: -10%;

  text-align: center;
  justify-content: center;
  align-items: center;
}



.form-container-sm {
  width: 60vw;
  height: 100%;
  border-radius: 40px;
  display: flex;
  position: relative;

  min-height: 600px;
}


.raffle-pannel {
  flex: 50%; 
  flex-direction: column;
  background: linear-gradient(30deg, rgba(23, 57, 85, 0.64) 0%, rgba(70, 113, 160, 0.64) 100%);
  opacity: 0.8; 
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
}


.raffle-pannel-sm {
  flex-direction: column;
  background: linear-gradient(30deg, rgba(23, 57, 85, 0.64) 0%, rgba(70, 113, 160, 0.64) 100%);
  opacity: 0.8; 
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
}


.raffle-ticket-display {
  width: 60%;
  height: 60%;

  min-height:300px;

  border-radius: 22px;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  outline: 1px solid var(--clayblue-primary);

}


.raffle-ticket-bio p {
  font-family: ShortStack;  
  font-size: calc(10px + 1vmin);
  text-transform: uppercase;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 12%;
}


.raffle-ticket-button:hover {
  box-shadow: 0px 0px 12px var(--clayblue-primary);
  transition: 0.4s ease-in-out;
}


.form-images {
  background-color: rgba(0, 0, 0, 0.819);
  height: 100%;
  width: 40%;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  contain: content;
 
}


.form-images-sm {
  background-color: rgba(0, 0, 0, 0.819);
  height: 50%;
  width: 100%;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  contain: content;
 
}


.form-images-sm img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}



.form-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}



.winner-form {
  text-align: left;
  padding-left: 10%;

}

.address-form {
  text-align: left;
  padding-left: 10%;
  display: flex;
  flex-direction: row;
}

.address-form input[type=text] {
  width:82%;
  background: rgba(0, 0, 0, 0.33);
  font-family: ShortStack;
  padding: 10px 20px;
  margin: 0;
  display: block;
  outline: 3px solid #01010170;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 5%;
  margin-top: 4%;
  margin-right: -1em;
}

.address-form  input[type=text]:focus {
  background: rgba(0, 0, 0, 0.5);
  outline:  3px solid var(--clayblue-primary);
}


.raffle-ticket-button {
  font-family: ShortStack;
  letter-spacing: 0.13em;
  font-size: calc(10px + 1.5vmin);
  border-radius: 15px;
  text-shadow: -3px 0px 0px rgba(0, 0, 0, 0.8);
  background: rgba(0, 0, 0, 0.30);
  outline:  3px solid var(--clayblue-primary);
  border: none;
  color: white;
  padding: 10px 42px;
  cursor: pointer;
  margin-top: 1%;

}


.raffle-ticket-button:hover {
  background-color: var(--clayblue-primary);
  transition: 1s ease-in-out;
}



.ticket-quantity-button {
  
  font-size: 25px;
  /*border-radius: 50px;
  background: rgba(0, 0, 0, 0.30);
  outline:  3px solid var(--clayblue-primary);*/
  border: none;
  color: white;

  cursor: pointer;

  background-color: rgba(255,255,255, 0);

  display: flex;
  justify-content: center;
  align-items: center; 

}


/*.ticket-quantity-button:hover {
  background-color: var(--clayblue-primary);
  transition: 1s ease-in-out;
}*/



.SidePannel {
  border-radius: 00px 50px 50px 0px;
  background: transparent;
  
  
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-between;

}

.SidePannelInner {
  border-radius: 00px 50px 50px 0px;
  background: linear-gradient(30deg, rgba(23, 57, 85, 0.66) 0%, rgba(70, 113, 160, 0.4) 100%);
  width: 30vw;
  height: 90vh;
  position: fixed;
  left: 0;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-between;
  contain: content;
}


.Info {
  font-family: ShortStack;
  color: rgba(255, 255, 255, 0.7);
  display: inline-flex;
  font-size: 0.7rem;
  gap: 50px;
  align-items: center;
  margin-left: 30px;
  margin-right: 40px;
  margin-top: -40px;

}

.Eligibility h2 {
  inline-size: 130px;
  overflow-wrap: break-word;
    text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
}



.number {
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
  padding: 15px;
  max-width: 50px;
  min-width: 20px;
  width: fit-content;
  border-radius: 10px;
  font-size: 1.3rem;
  text-align: center;
  align-items: center;
}


.Price {
  font-family: ShortStack;
  color: rgba(255, 255, 255, 0.7);
  display: inline-flex;
  font-size: 0.7rem;
  gap: 40px;
  align-items: center;
  margin-top: -30px;

}