@font-face {
  font-family: ClayNationFont;
  src: local("ClayNationFont"),
  url("./Fonts/ClayNationFont.otf");
  }
  @font-face {
    font-family : ClayFont;
    src: local("NewFont"),
    url("./Fonts/NewFont.otf");
    }



.PFPApp {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  @media screen and (max-width: 768px) and (orientation: landscape) {
    min-height: 100vh;
    align-items: none;
    justify-content: none;
    flex-direction: none;
    display: block;
    
  }

}

.logo {
  height: 15vmin;
  pointer-events: none;
  position: absolute;
  top: 3rem;
  left: 5rem;
  @media screen and (max-width: 768px) {
    height: 20vmin;
    top: 2rem;
    left:50%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    height: 15vmin;
    pointer-events: none;
    position: absolute;
    top: 2rem;
    left: 4rem;
    
  }
}

.image {
  max-height: 55vh;
  height: auto;
  outline: 7px solid var(--panel-background);
  object-fit: contain;
  display: block;
  @media screen and (max-width: 768px) {
    max-width: 60vw;
    max-height: 50vh;
    object-fit: contain;
  }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    max-width: 30vw;
    max-height: 30vh;
    object-fit: contain;
    
  }
}

.asset {
  width: 6vw;
  cursor: pointer; 
  margin-right: 2rem;
  @media screen and (max-width: 768px) {
    width: 15vw;
    margin-right: 0;
  }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    width:7vw;

  }
}
