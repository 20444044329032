.ring {
  position: fixed;
  top: 0;
  left: 0;



  width: 25px;
  height: 25px;
  border: 2px solid rgba(31, 30, 30, 0.808);
  border-radius: 100%;



  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid rgba(194, 255, 203, 0.7);
  box-shadow: 0 0 5px rgba(194, 255, 203, 0.4);
  transition: background-color 0.3s, width 0.3s, height 0.3s, opacity 0.3s;



  transform: translate(-50%, -50%);
  
  z-index: 4999;
  pointer-events: none;
}

ring:hover {
  background-color: rgba(194, 255, 203, 0.53);
  width: 25px;
  height: 25px;
  opacity: 1;
}

/*.ring.clicked {
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(194, 255, 203, 1);
  box-shadow: 0 0 10px rgba(194, 255, 203, 0.4);
} */