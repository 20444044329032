.progress-container {
  width: 100%;
  height: 30px;
  background-color: linear-gradient(180deg, rgba(69, 62, 112, 0.95) 0%, #1D3A3D 100%),
              linear-gradient(rgba(239, 132, 99, 0.78) 4.03%, #CCA7B5 44.47%, rgba(96, 157, 183, 0.82) 100%);

  border: 2px solid rgba(194, 255, 203, 0.53);

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  border-radius: 50px;
  
}





.progress-bar {
  height: 100%;

  background: linear-gradient(45deg, #B1F2B7, rgba(194, 255, 203, 0.93));
  background-clip: padding-box, border-box;


  background: linear-gradient(90deg, rgba(237,166,176,1) 0%, rgba(245,179,161,1) 34%, rgba(172,213,237,1) 65%, rgba(177,242,183,1) 96%);
  boxShadow: inset -3px 3px 2px rgb(255, 255, 240, 0.2), inset 0px -3px 2px rgba(0, 0, 0, 0.25);


  width: 0%;
  transition: width 1s;

  border-radius: 50px;
}






.progress-text {
  font-family: ShortStack;

  margin-bottom: 10px; 
  color: white;
  font-size: 24px;

  color: rgba(255, 255, 240, 0.744);

  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
}