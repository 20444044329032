html, body {
    height:100%;
} 


@font-face {
    font-family: gloriahallelujah;
    src: url(gloriahallelujah.ttf);
}

@font-face {
    font-family: shortstack;
    src: url(shortstack.ttf);
}


@font-face {
  font-family: ButtonFont;
  src: url("ButtonFont.otf");

  
}


    @font-face {
      font-family : ClayFont;
      src: local("NewFont"),
      url("NewFont.otf");
      }



    @font-face {
      font-family : ClayNationFont;
      src: local("ClayNationFont"),
      url("ClayNationFont.otf");
      }




      @font-face {
      font-family : ClayFontJika;
      src: local("ClayFontJika"),
      url("ClayFontJika.otf");
      }


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


