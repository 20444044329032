.totalBaked {
    top: 100px;
    position: relative;
    animation-name: baked-tray;
    -webkit-animation-name: baked-tray;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease ;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    animation-direction: normal;
    -webkit-animation-direction: normal;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}


@keyframes baked-tray {
    0%{
      transform: translateY(0);
    }
    100%{
      transform: translateY(-100px);
    }
  }


.tokenBakedImg {
    width: 100%;
}


.totalBakedTxt {
    font-size: calc(0.5em + 0.3vh + 0.3vw);
}


.totalBakedNumber {
    font-size: calc(0.4em + 0.6vh + 0.5vw);
    color: bisque;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    margin-bottom: 1px;
}


.bakedBackground {
    margin-top: 1px;
    background-color: rgba(0, 0, 0, 0.202);
    width: 30%;
    max-width: 240px;
    min-height: 2px;
    border-radius: 10px;
    z-index: 1;
}




.txsComplete {
    min-width: 10%;
    max-width: 240px;
    height: 100%;
    background: rgba(217, 217, 217, 0.18);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    position:relative;
    cursor: pointer;

    border: 3px solid rgba(255,255,255,0);
}


.txsComplete:hover {
    min-width: 10%;
    max-width: 240px;
    height: 100%;
    background: rgba(217, 217, 217, 0.18);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    position:relative;
    cursor: pointer;

    border: 3px solid rgba(0, 230, 0, 0.6)
}

