.canvas_container
{
    background: transparent;
    border: 0px solid #aaa;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 515px;
    margin: 20px 20px;
    overflow: hidden;
    height: 323px;
    width: 323px;
    margin-top: -500px;
    margin-left: 38.5px;

    /* this fixes the overflow:hidden in Chrome/Opera */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}