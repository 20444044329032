.CN {


transition: ease all .3s;

cursor: pointer;

}

.CN:hover .CN-img  {

  transform: translateY(-10px);
  transition: ease-in-out all .6s;



}


.CN:hover .CN-background {

  cursor: pointer;


  transition: ease all .3s;

  box-shadow: 0px 0px 22px rgba(254, 100, 100, 0.6);
  
  }

.CN-background {


width: 150px;
height: 180px;

background: radial-gradient(68% 60% at 50% 5.01%, #fc8b8ba6 40%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%);
border-radius: 18px;

outline:1.2px solid #fb8b8ba5;


backdrop-filter: blur(7px);

z-index: 100;

transition: ease all .3s;

}


.CN-img {

  position: relative;

top:-40px;
z-index: 1;

filter: drop-shadow(-7px -4px 3px rgba(0, 0, 0, 0.4));

width: 62%;
height: auto;

transition: ease all .3s;
}


.CN-title {

  position: relative;

  top: -42px;

  font-size: 0.8rem;

  color: rgba(255, 255, 255, 0.7);

  /* letter glow */
  
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);


}

.CN-volume {

  position: relative;

  top:-32px;
  left: 20px;

  

width: 50px;
height: 30px;

background: rgba(0, 0, 0, 0.17);

border-radius: 7px;

backdrop-filter: blur(2px);

z-index: 1;


}


.CN-floor{

  position: relative;

  top:-62px;
  left: 80px;

  

width: 50px;
height: 30px;

background: rgba(0, 0, 0, 0.17);

border-radius: 7px;

backdrop-filter: blur(2px);

z-index: 1;


}


.CN-volume-txt {



  font-size: 0.5rem;

  color: rgba(255, 255, 255, 0.5);

  /* letter glow */
  
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);

  padding-top: 4px;


}



.CN-volume-nmb {



  font-size: 0.6rem;

  color: rgba(255, 171, 171, 0.8);

  /* letter glow */
  
  text-shadow: 0px 0px 2px rgba(249, 146, 146, 0.608);


}



.CN-floor-txt {



  font-size: 0.5rem;

  color: rgba(255, 255, 255, 0.5);

  /* letter glow */
  
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);

  padding-top: 4px;


}



.CN-floor-nmb {



  font-size: 0.6rem;

  color: rgba(255, 171, 171, 0.8);

  /* letter glow */
  
  text-shadow: 0px 0px 2px rgba(249, 146, 146, 0.608);


}












/* GC */

.GC {

  
  transition: ease all .3s;

  cursor: pointer;
  
  }
  
  .GC:hover .GC-img  {
  
    transform: translateY(-10px);
    transition: ease-in-out all .6s;
  
    cursor: pointer;  
  
  }
  
  
  .GC:hover .GC-background {
  
    cursor: pointer;
  
  
    transition: ease all .3s;
  
    box-shadow: 0px 0px 22px #6ac8ff8a;

    cursor: pointer;
    
    }
  
  .GC-background {
  
  
  width: 150px;
  height: 180px;
  
  background: radial-gradient(68% 60% at 50% 5.01%, #6AC9FF 30%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%);
  border-radius: 18px;
  
  outline:1.2px solid #6ac8ffaa;
  
  
  backdrop-filter: blur(7px);
  
  z-index: 100;
  
  transition: ease all .3s;

  cursor: pointer;
  
  }
  
  
  .GC-img {
  
    position: relative;
  
  top:-38px;
  z-index: 1;

  opacity: 0.9;
  
  filter: drop-shadow(-7px -4px 3px rgba(0, 0, 0, 0.5));
  
  width: 60%;
  height: auto;
  
  transition: ease all .3s;

  cursor: pointer;
  }
  
  
  .GC-title {
  
    position: relative;
  
    top: -37px;
  
    font-size: 0.8rem;
  
    color: rgba(255, 255, 255, 0.7);
  
    /* letter glow */
    
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
    cursor: pointer;
  
  }
  
  .GC-volume {
  
    position: relative;
  
    top:-26px;
    left: 20px;
  
    
  
  width: 50px;
  height: 30px;
  
  background: rgba(0, 0, 0, 0.17);
  
  border-radius: 7px;
  
  backdrop-filter: blur(2px);
  
  z-index: 1;
  
  
  }
  
  
  .GC-floor{
  
    position: relative;
  
    top:-57px;
    left: 80px;
  
    
  
  width: 50px;
  height: 30px;
  
  background: rgba(0, 0, 0, 0.17);
  
  border-radius: 7px;
  
  backdrop-filter: blur(2px);
  
  z-index: 1;
  
  
  }
  
  
  .GC-volume-txt {
  
  
  
    font-size: 0.5rem;
  
    color: rgba(255, 255, 255, 0.5);
  
    /* letter glow */
    
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  
    padding-top: 4px;
  
  
  }
  
  
  
  .GC-volume-nmb {
  
  
  
    font-size: 0.6rem;
  
    color: #79d9f9;
  
    /* letter glow */
    
    text-shadow: 0px 0px 2px rgba(108, 217, 250, 0.608);
  
  
  }
  
  
  

  
  
  
  .GC-floor-nmb {
  
  
  
    font-size: 0.6rem;
  
    color: #79d9f9;
  
    /* letter glow */
    
    text-shadow: 0px 0px 2px rgba(108, 217, 250, 0.608);
  
  
  }



/* PITCHES */

.PITCHES{


  
  transition: ease all .3s;

  cursor: pointer;
  
  }
  
  .PITCHES:hover .PITCHES-img  {
  
    transform: translateY(-10px);
    transition: ease-in-out all .6s;
  
  
  }
  
  
  .PITCHES:hover .PITCHES-background {
  
    cursor: pointer;
  
  
    transition: ease all .3s;
  
    box-shadow: 0px 0px 22px #a8f09681;
    
    }
  
  .PITCHES-background {
  
  
  width: 150px;
  height: 180px;
  
  background: radial-gradient(68% 60% at 50% 5.01%, #A8F096 30%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%);
  border-radius: 18px;
  
  outline:1.2px solid #a8f09681;
  
  
  backdrop-filter: blur(7px);
  
  z-index: 100;
  
  transition: ease all .3s;
  
  }
  
  
  .PITCHES-img {
  
    position: relative;
  
  top:-38px;
  z-index: 1;
  
  filter: drop-shadow(-7px -4px 3px rgba(0, 0, 0, 0.5));
  
  width: 87%;
  height: auto;
  
  transition: ease all .3s;
  }
  
  
  .PITCHES-title {
  
    position: relative;
  
    top: -37px;
  
    font-size: 0.8rem;
  
    color: rgba(255, 255, 255, 0.7);
  
    /* letter glow */
    
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  
  
  }
  
  .PITCHES-volume {
  
    position: relative;
  
    top:-26px;
    left: 20px;
  
    
  
  width: 50px;
  height: 30px;
  
  background: rgba(0, 0, 0, 0.17);
  
  border-radius: 7px;
  
  backdrop-filter: blur(2px);
  
  z-index: 1;
  
  
  }
  
  
  .PITCHES-floor{
  
    position: relative;
  
    top:-57px;
    left: 80px;
  
    
  
  width: 50px;
  height: 30px;
  
  background: rgba(0, 0, 0, 0.17);
  
  border-radius: 7px;
  
  backdrop-filter: blur(2px);
  
  z-index: 1;
  
  
  }
  
  
  .PITCHES-volume-txt {
  
  
  
    font-size: 0.5rem;
  
    color: rgba(255, 255, 255, 0.5);
  
    /* letter glow */
    
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  
    padding-top: 4px;
  
  
  }
  
  
  
  .PITCHES-volume-nmb {
  
  
  
    font-size: 0.6rem;
  
    color: #a6f891b5;
  
    /* letter glow */
    
    text-shadow: 0px 0px 2px #a8f09681;
  
  
  }
  
  
  

  
  
  
  .PITCHES-floor-nmb {
  
  
  
    font-size: 0.6rem;
  
    color: #79d9f9;
  
    /* letter glow */
    
    text-shadow: 0px 0px 2px rgba(108, 217, 250, 0.608);
  
  
  }



















