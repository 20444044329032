/* WEARABLES */

.WEARABLES{
  transition: ease all .3s;
  cursor: pointer;
  }
  
  .WEARABLES:hover .WEARABLES-img  {
    transform: translateY(-10px);
    transition: ease-in-out all .6s;
  }
  
  
  .WEARABLES:hover  .WEARABLES-background {
    cursor: pointer;
    transition: ease all .3s;
    box-shadow: 0px 0px 22px #2774e7;
  }
  
  .WEARABLES-background {
    width: 150px;
    height: 180px;  
    background: radial-gradient(68% 60% at 50% 5.01%, #2774e7 30%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%);
    border-radius: 18px;
    outline:1.2px solid #2774e79f;
    backdrop-filter: blur(7px);
    z-index: 100;
    transition: ease all .3s;
  }
  
  
  .WEARABLES-img {
    position: relative;
    top:-70px;
    z-index: 1;
    filter: drop-shadow(-7px -4px 3px rgba(0, 0, 0, 0.5));
    width: 83%;
    height: auto;
    transition: ease all .3s;
  }
  
  
  .WEARABLES-title {
    position: relative;
    top: -120px;
    font-size: 0.8rem;  
    color: rgba(255, 255, 255, 0.7);
    /* letter glow */
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
    font-family: 'ShortStack';
  }




 /* COMING SOON */

.COMING-SOON-CARD{
  transition: ease all .3s;
  cursor: pointer;
}
  
  .COMING-SOON-CARD:hover .COMING-SOON-CARD-img  {
    transform: translateY(-10px);
    transition: ease-in-out all .6s;
  }
  
  
  .COMING-SOON-CARD:hover .COMING-SOON-CARD-background {
    cursor: pointer;
    transition: ease all .3s;
    box-shadow: 0px 0px 22px #b665f3d0;
  }
  

.COMING-SOON-CARD-background {  
  width: 150px;
  height: 180px;
  background: radial-gradient(68% 60% at 50% 5.01%, #b565f3 20%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%);
  border-radius: 18px;
  outline: 1.2px solid #b665f380;
  backdrop-filter: blur(7px);
  z-index: 100;
  transition: ease all .3s;
}
  
  
.COMING-SOON-CARD-img {  
  position: relative;
  opacity:0.9;
  top:-30px;
  z-index: 1;
  filter: drop-shadow(-7px -4px 3px rgba(0, 0, 0, 0.5));
  width: 100%;
  height: auto;
  transition: ease all .3s;
}
  
  
.COMING-SOON-CARD-title {
  position: relative;
  top: -20px;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  /* letter glow */
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  font-family: 'ShortStack';
}
  



/* ASSET PACKS */

.ASSET-PACKS{
  transition: ease all .3s;
  cursor: pointer;  
}
  
  .ASSET-PACKS:hover .assets-img  {
    transform: translateY(-10px);
    transition: ease-in-out all .6s;
  }
  
  
  .ASSET-PACKS:hover .ASSETS-background {
    cursor: pointer;
    transition: ease all .3s;
    box-shadow: 0px 0px 22px #25c3b1aa; 
  }
  
  .ASSETS-background {
    width: 150px;
    height: 180px;
    background: radial-gradient(68% 60% at 50% 5.01%, hsl(173, 75%, 40%) 30%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%);
    border-radius: 18px;
    outline:1.2px solid #25c3b1aa;
    backdrop-filter: blur(7px);
    z-index: 100;
    transition: ease all .3s;  
  }
  
  
  .assets-img {
    position: relative;
    top:-38px;
    z-index: 1;
    filter: drop-shadow(-7px -4px 3px rgba(0, 0, 0, 0.5));
    width: 77%;
    height: auto;
    transition: ease all .3s;
  }
  
  
  .ASSETS-title {
    position: relative;
    top: -37px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    /* letter glow */
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  }
  

   /* IN-GAME ASSET PACKS */

.INGAME-ASSETS{

  position: absolute;
  top:400px;
  
  left: 38%;
  
  transition: ease all .3s;

  cursor: pointer;
  
  }
  
  .INGAME-ASSETS:hover .ingame-img  {
  
    transform: translateY(-10px);
    transition: ease-in-out all .6s;
  
  
  }
  
  
  .INGAME-ASSETS:hover  .INGAME-background {
  
    cursor: pointer;
  
  
    transition: ease all .3s;
  
    box-shadow: 0px 0px 22px #FDBA10;
    
    }
  
  .INGAME-background {
  
  
  width: 150px;
  height: 180px;
  
  background: radial-gradient(68% 60% at 50% 5.01%, #eba903d3 30%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%);
  border-radius: 18px;
  
  outline:1.2px solid #eba9039b;
  
  
  backdrop-filter: blur(7px);
  
  z-index: 100;
  
  transition: ease all .3s;
  
  }
  
  
  .ingame-img {
  
    position: relative;
  
  top:-38px;
  z-index: 1;
  
  filter: drop-shadow(-7px -4px 3px rgba(0, 0, 0, 0.5));
  
  width: 77%;
  height: auto;
  
  transition: ease all .3s;
  }
  
  
  .INGAME-ASSETS-title {
  
    position: relative;
  
    top: -37px;
  
    font-size: 0.8rem;
  
    color: rgba(255, 255, 255, 0.7);
  
    /* letter glow */
    
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  
  
  }
  
 
  
  
  /* COMING SOON */

.COMING-SOON{
  position: absolute;
  top:400px;
  left: 68%;
  transition: ease all .3s;
  cursor: pointer;
}
  
  .COMING-SOON:hover .questionmark-img  {
    transform: translateY(-10px);
    transition: ease-in-out all .6s;
  }
  
  
  .COMING-SOON:hover  .COMING-SOON-background {
  
    cursor: pointer;
  
  
    transition: ease all .3s;
  
    box-shadow: 0px 0px 22px #b665f3d0;
    
    }
  
    .COMING-SOON-background {
  
  
  width: 150px;
  height: 180px;
  
  background: radial-gradient(68% 60% at 50% 5.01%, #b565f3 20%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%);
  border-radius: 18px;
  
  outline:1.2px solid #b665f380;
  
  
  backdrop-filter: blur(7px);
  
  z-index: 100;
  
  transition: ease all .3s;
  
  }
  
  
  .questionmark-img {
  
    position: relative;
    opacity:0.9;
  
  top:-40px;
  z-index: 1;
  
  filter: drop-shadow(-7px -4px 3px rgba(0, 0, 0, 0.5));
  
  width: 57%;
  height: auto;
  
  transition: ease all .3s;
  }
  
  
  .COMING-SOON-title {
  
    position: relative;
  
    top: -65px;
  
    font-size: 0.8rem;
  
    color: rgba(255, 255, 255, 0.7);
  
    /* letter glow */
    
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  
  
  }