@font-face {
  font-family: PixelFont;
  src: local("VT323"),
  url("./Fonts/VT323.ttf")
}
@font-face {
font-family : ShortStack;
src: local("ShortStack"),
url("./Fonts/ShortStack.ttf");
}

@font-face {
  font-family : ButtonFont;
  src: local("ButtonFont"),
  url("./Fonts/ButtonFont.otf");
  }

/*  html,
  body,
  #root {
    cursor: url('Images/cursor32.png'), auto; 
  }
  */


.slide-in {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  background: #00AEEF;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  background: #00AEEF;
  transform-origin: top;
}



.argy  {
  width: 22%;
  height: auto;
  opacity: 0;

}


@media screen and (max-width: 768px) {
  .argy {
    width: 50%;
    padding: 3em 0;
  }
}

@media screen and (max-width: 768px) and (orientation: landscape){
  .argy {
    width: 20%;
    padding: 1em 0;
  }
}


.link-symbol {
  width: 20%;
  height: auto;
  opacity: 0;
}


.padlock {
  width: 22%;
  height: auto;
  opacity: 0;
  padding: 2em 0;
}

@media screen and (max-width: 768px) {
  .padlock {
    width: 50%;
  }
}

@media screen and (max-width: 768px) and (orientation: landscape) {

  .padlock {
    width: 15%;
  }
}

@media screen and (max-width: 768px) {
  .link-symbol {
    width: 60%;
  }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  .link-symbol {
    width: 15%;
  }
}


.walletImg {
  width: 22px;
  height: 22px;
  padding: 0 1em;
 
}

@media screen and (max-width: 768px) and (orientation: landscape) {
  .walletImg {
    width: 12px;
    height: 12px;
   
  }
}


