

.Zoom-btn {
    position: relative;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    border: 18px;
    outline: none;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(69, 62, 112, 0.58) 0%, #1D3A3D 100%);
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25), inset 8px -8Px 8px rgba(0, 0, 0, 0.3), inset -8px 0.662804px 8px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(50px);

    color: bisque;


    -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-o-user-select: none;
user-select: none;
}



.Zoom-btn-2:before {
    content: '';
    background: linear-gradient(-90deg,  #FDBCAC, rgba(148, 130, 244, 0.950741), #84D3FF , rgba(148, 130, 244, 0.950741) , rgba(246, 141, 141, 0.89) );
    position: absolute;



    
  


    background-size: 200%;
    z-index: -1;
    width: calc(100% + 1px);
    height: calc(100% + 1px);




    opacity:1;

    
   

    

    border-radius: 5px;




    
}


.Zoom-btn-2:after
{
    content: '';
    position: absolute;
    inset: 2px;

    background: linear-gradient(180deg, rgba(69, 62, 112, 0.58) 0%, #1D3A3D 100%);
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25), inset 8px -8Px 8px rgba(0, 0, 0, 0.3), inset -8px 0.662804px 8px rgba(0, 0, 0, 0.3);



    backdrop-filter: blur(40px);


    

    border-radius: 5px;

   
}


.Zoom-text {


font-size: 25px;

background: linear-gradient(-90deg,  #FDBCAC, rgba(148, 130, 244, 0.950741), #84D3FF , rgba(148, 130, 244, 0.950741) , rgba(246, 141, 141, 0.89) );

background-size: 200%;

background-clip: text;

-webkit-text-fill-color: transparent;


text-shadow: 0px 2.6px 2.6px rgba(0, 0, 0, 0.17);


z-index: 1;




}



.Zoom-btn:active {

  transform: translateY(2px);
}