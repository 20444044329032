
@font-face {
  font-family: ShortStack;

  src: local("ShortStack"),
 url("./ShortStack.ttf")
  
}

@font-face {
  font-family: SingleDay;

  src: local("SingleDay"),
 url("./SingleDay.ttf")
  
}



.chat {
  position: absolute;
  width: 370px;
  height: 360px;
  left:30px;
  bottom: 30px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);
  box-shadow: 0px 0px 21px rgba(174, 245, 184, 0.25);
  backdrop-filter: blur(6px);
  border-radius: 14px;
  transition: ease-in-out all 0.3s; 
  font-family: 'ShortStack';
  z-index: 99;
  user-select: none;

  -ms-overflow-style: none;  
  scrollbar-width: none;
}


::-webkit-scrollbar {
  display: none;
}



.chat_folded {
  position: absolute;
  width: 370px;
  height: 120px;
  left:30px;
  bottom: 30px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);
  box-shadow: 0px 0px 21px rgba(174, 245, 184, 0.25);
  backdrop-filter: blur(6px);
  border-radius: 14px;
  transition: ease-in-out all 0.3s; 
  font-family: 'ShortStack';
  z-index: 100;
  user-select: none;
}


.chat::-webkit-scrollbar {
  width: 8px;
}

.chat::-webkit-scrollbar-thumb {
  background: rgba(177, 242, 183, 0.5);
  border-radius: 10px;
}

.chat::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}



.chatSettings {
  position: absolute;
  right: 25px;
  top:15px;
  opacity: 0.7;
  cursor: pointer;
  transition: ease-in-out all 0.3s; 

}

.chatSettings:hover {
  transition: ease-in-out all 0.3s; 
  opacity: 1;
}





.avatarimgA {
  width:50px;
  height: auto;
  border-radius: 50px;
  border: 3px solid rgba(0, 0, 0, 0.29);
  display: inline-block;
  z-index: 1;
  position: absolute;
  left:30px;
  top: 62px;

}

.lineA {

  background: rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding:10px;
  width: 70%;
  color: rgba(255, 255, 240, 0.744);
  font-family: SingleDay;
  font-size: 1.1rem;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  position: absolute;
  top: 70px;
  right: 25px;

}



.avatarimgB {
  width:50px;
  height: auto;
  border-radius: 50px;
  border: 3px solid rgba(0, 0, 0, 0.29);
  display: inline-block;
  z-index: 1;
  position: absolute;
  right:30px;
  top: 122px;

}


.lineB {

  background: rgba(175, 170, 170, 0.25);
  border-radius: 14px;
  padding:10px;
  width: 60%;
  color: rgba(255, 255, 240, 0.744);
  font-family: SingleDay;
  font-size: 1.1rem;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  position: absolute;
  top: 130px;
  left: 65px;

}

.lineC {

  background: rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding:10px;
  width: 70%;
  color: rgba(255, 255, 240, 0.744);
  font-family: SingleDay;
  font-size: 1.1rem;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
  position: absolute;
  top: 190px;
  left: 60px;

}

.avatarimgC {
  width:50px;
  height: auto;
  border-radius: 50px;
  border: 3px solid rgba(0, 0, 0, 0.29);
  display: inline-block;
  z-index: 1;
  position: absolute;
  left:30px;
  top: 184px;

}


.type {
  position: absolute;
  width: 230px;
  height: 46px;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(194, 255, 203, 0.53);
  border-radius: 14px;
  font-size: 16px;
  padding-left: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  caret-color: #B1F2B7;
  color: #fff;
  font-family: 'ShortStack';
}


.type:focus,
.type:active {
border: 1.5px solid #b1f2b7d7;
transition: ease-in-out all 0.3s; 
outline: none;
}

.send {

  font-family: ShortStack;
  color: #B1F2B7;
  background: rgba(0, 0, 0, 0.42);
  border-radius: 12px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  right: 20px;
  bottom: 25px;
  cursor: pointer;
  transition: ease-in-out all 0.3s; 
  color: #fff;
  user-select: none;
}

.send:hover {
  border: 1.5px solid #b1f2b7d7;
  transition: ease-in-out all 0.3s; 
  background-color: rgba(255, 255, 255, 0.1);
}


.chat img{

  width: 60px; 
  height: 60px; 
  border-radius: 50px;

  border: 3px solid rgba(0, 0, 0, 0.29);

  -webkit-user-drag: none; 
  -khtml-user-drag: none; 
  -moz-user-drag: none; 
  -o-user-drag: none; 
  user-drag: none;
}









