.container {
    width: 100%;
    text-align: center;
  }
  
  .progress-fluid .progress-bar {
    width: 100%; 
    background-color: rgba(83, 201, 238, 0.7);  
    animation: progressAnimation 6s infinite;
  }

.progress2 {
  padding: 6px;
  border-radius: 30px;
  background: linear-gradient(90deg, rgba(51,37,70,0.2) 0%, rgba(43,54,78,0.2) 50%, rgba(29,58,61,0.2) 100%);
  box-shadow: inset 0 -2px 12px rgba(0, 0, 0, 0.4), 0 1px 12px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 12px;
  border-radius: 20px;

  background: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    
  transition: 0.10s linear;  
  transition-property: width, background-color;    
}


@keyframes cn0 {
0%   { width:0%; background-color: rgba(255, 156, 131, 1);}
100% { width:7%; background-color: #0099EF; }
}
@keyframes cn1 {
0%   { width:7%; background-color: rgba(255, 156, 131, 1);}
100% { width:14%; background-color: #0099EF; }
}
@keyframes cn2 {
0%   { width:14%; background-color: rgba(255, 156, 131, 1);}
100% { width:21%; background-color: #0099EF; }
}
@keyframes cn3 {
0%   { width:21%; background-color: rgba(255, 156, 131, 1);}
100% { width:29%; background-color: #0099EF; }
}
@keyframes cn4 {
0%   { width:29%; background-color: rgba(255, 156, 131, 1);}
100% { width:36%; background-color: #0099EF; }
}
@keyframes cn5 {
0%   { width:36%; background-color: rgba(255, 156, 131, 1);}
100% { width:43%; background-color: #0099EF; }
}
@keyframes cn6 {
0%   { width:43%; background-color: rgba(255, 156, 131, 1);}
100% { width:50%; background-color: #0099EF; }
}
@keyframes cn7 {
0%   { width:50%; background-color: rgba(255, 156, 131, 1);}
100% { width:57%; background-color: #0099EF; }
}
@keyframes cn8 {
0%   { width:57%; background-color: rgba(255, 156, 131, 1);}
100% { width:64%; background-color: #0099EF; }
}
@keyframes cn9 {
0%   { width:64%; background-color: rgba(255, 156, 131, 1);}
100% { width:71%; background-color: #0099EF; }
}
@keyframes cn10 {
0%   { width:71%; background-color: rgba(255, 156, 131, 1);}
100% { width:79%; background-color: #0099EF; }
}
@keyframes cn11 {
0%   { width:79%; background-color: rgba(255, 156, 131, 1);}
100% { width:86%; background-color: #0099EF; }
}
@keyframes cn12 {
0%   { width:86%; background-color: rgba(255, 156, 131, 1);}
100% { width:93%; background-color: #0099EF; }
}
@keyframes cn13 {
0%   { width:93%; background-color: rgba(255, 156, 131, 1);}
100% { width:100%; background-color: #0099EF; }
}


@keyframes cn14 {
0%   { width:100%; background-color: rgba(255, 156, 131, 1);}
100% { width:100%; background-color: #0099EF; }
}




@keyframes gc0 {
0%   { width:0%; background-color: rgba(127, 212, 239, 1);}
100% { width:7%; background-color: #0099EF; }
}
@keyframes gc1 {
0%   { width:7%; background-color: rgba(127, 212, 239, 1);}
100% { width:14%; background-color: #0099EF; }
}
@keyframes gc2 {
0%   { width:14%; background-color: rgba(127, 212, 239, 1);}
100% { width:21%; background-color: #0099EF; }
}
@keyframes gc3 {
0%   { width:21%; background-color: rgba(127, 212, 239, 1);}
100% { width:29%; background-color: #0099EF; }
}
@keyframes gc4 {
0%   { width:29%; background-color: rgba(127, 212, 239, 1);}
100% { width:36%; background-color: #0099EF; }
}
@keyframes gc5 {
0%   { width:36%; background-color: rgba(127, 212, 239, 1);}
100% { width:43%; background-color: #0099EF; }
}
@keyframes gc6 {
0%   { width:43%; background-color: rgba(127, 212, 239, 1);}
100% { width:50%; background-color: #0099EF; }
}
@keyframes gc7 {
0%   { width:50%; background-color: rgba(127, 212, 239, 1);}
100% { width:57%; background-color: #0099EF; }
}
@keyframes gc8 {
0%   { width:57%; background-color: rgba(127, 212, 239, 1);}
100% { width:64%; background-color: #0099EF; }
}
@keyframes gc9 {
0%   { width:64%; background-color: rgba(127, 212, 239, 1);}
100% { width:71%; background-color: #0099EF; }
}
@keyframes gc10 {
0%   { width:71%; background-color: rgba(127, 212, 239, 1);}
100% { width:79%; background-color: #0099EF; }
}
@keyframes gc11 {
0%   { width:79%; background-color: rgba(127, 212, 239, 1);}
100% { width:86%; background-color: #0099EF; }
}
@keyframes gc12 {
0%   { width:86%; background-color: rgba(127, 212, 239, 1);}
100% { width:93%; background-color: #0099EF; }
}
@keyframes gc13 {
0%   { width:93%; background-color: rgba(127, 212, 239, 1);}
100% { width:100%; background-color: #0099EF; }
}

@keyframes gc14 {
0%   { width:100%; background-color: rgba(127, 212, 239, 1);}
100% { width:100%; background-color: #0099EF; }
}



.Baking {
  color: bisque;
  position: relative;
  font-size: 0.7rem;
  float: left;
  margin-left: 10px;
  margin-top: 2px;
}


.Days {
  color: bisque;
  position: relative;
  font-size: 0.7rem;
  float: right;
  margin-right: 10px;
  margin-top: 2px;
}


