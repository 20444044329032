.unstaked-frame {

width: 300px;


align-items: center;
text-align: center;


transform: rotate(-5deg);


animation: frame-wobble 1.1s ease-out 0s 1 normal none;

}



.frame-img {

    width: 300px;

    position: relative;

}



.staked-txt {

    position: relative;

    top: -130px; 

    font-size: 30px;
}



@keyframes frame-wobble {
    0%{
      transform: translateX(0%);
      transform-origin: 50% 50%;
    }
    15%{
      transform: translateX(-15px) rotate(6deg);
    }
    30%{
      transform: translateX(7px) rotate(-6deg);
    }
    45%{
      transform: translateX(-7px) rotate(3.6deg);
    }
    60%{
      transform: translateX(3px) rotate(-2.4deg);
    }
    75%{
      transform: translateX(-3px) rotate(1.2deg);
    }
    100%{
      transform: translateX(0%);
      transform-origin: 50% 50%;
    }
  }











  .join-btn {



    width: 160px;
    height: 65px;
    
    background: rgba(247, 247, 247, 0.59);
    
    border-radius: 18px;
    
      
  
    
    
    
       display: inline-flex;
       -webkit-box-align: center;
       align-items: center;
       -webkit-box-pack: center;
       justify-content: center;
    
    
       box-sizing: border-box;
       -webkit-tap-highlight-color: transparent;
    
    
       outline: none;
    
       border: 18px;
    
       cursor: pointer;
    
    
    
      }
    
    
    .join-txt {

    
         font-size: 16px;  
    }



  .join-btn:hover {


    box-shadow: 3px 4px 11px rgba(0, 0, 0, 0.4);


  }


    .join-btn:active{
    transform: translateY(2px);
  }







.staked-frame {

width: 300px;


align-items: center;
text-align: center;


transform: rotate(5deg);


animation: frame-wobble 1.1s ease-out 0s 1 normal none;

}








@keyframes frame-wobble {
    0%{
      transform: translateX(0%);
      transform-origin: 50% 50%;
    }
    15%{
      transform: translateX(-15px) rotate(6deg);
    }
    30%{
      transform: translateX(7px) rotate(-6deg);
    }
    45%{
      transform: translateX(-7px) rotate(3.6deg);
    }
    60%{
      transform: translateX(3px) rotate(-2.4deg);
    }
    75%{
      transform: translateX(-3px) rotate(1.2deg);
    }
    100%{
      transform: translateX(0%);
      transform-origin: 50% 50%;
    }
  }




.stakingAddress {
    min-width: 10%;
    max-width: 80%;
    height: 100%;
    background: rgba(217, 217, 217, 0.18);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    position:relative;
    cursor: pointer;

    border: 3px solid rgba(255,255,255,0);
}


.stakingAddress:hover {
    min-width: 10%;
    max-width: 80%;
    height: 100%;
    background: rgba(217, 217, 217, 0.18);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    position:relative;
    cursor: pointer;

    border: 3px solid rgba(255, 228, 196,0.6)
}




.tokensTextStaking {
    color: rgba(196, 185, 255, 0.8);
    font-size: calc(0.3em + 0.9vh);
}



.tokensNumberStaking {
    color: rgba(196, 185, 255, 1);
    font-size: calc(0.6em + 1.8vh);
    font-weight: 600;
    text-shadow: 0px 0px 2px  rgba(196, 185, 255, 0.5);
}



