.spinnerCenter {
  position: fixed;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 90px;
  height: 90px;
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top-color: #b7ffc1df;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}


.spinner {
  z-index: 9999;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #b7ffc1df;
  border-radius: 50%;
  animation: spin 1s infinite linear;

  margin-top: 50px;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}



