
.raffleBtn_dis{

    display: flex;
    font-size: 1rem;
    font-camily: ShortStack;
    justify-content: center;
    width: 160px;
    height: 25px;
    border-radius: 10px;
    margin-top: -2px;
    background-color: #FFA500;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid #FFA500;
    color: white;
    borderRadius: 10px;
    padding: 5px;
    z-index: 1000;

    cursor: pointer;

    padding: 5px;

    opacity: 0.5;

}







.raffleBtn2 {
display: flex;
justify-content: center;
align-items: center;
width: 160px;
height: 25px;
font-size: 1rem;
font-family: ShortStack, sans-serif;
border-radius: 10px;
margin-top: -2px;
background: linear-gradient(180deg, #ffae19, #ff871a);
border: 1px solid #ff8c00;
color: #fff;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -4px 4px rgba(255, 255, 255, 0.25);
cursor: default;
transition: all 0.2s ease-in-out;
padding: 5px;
}







.raffleBtn {
display: flex;
justify-content: center;
align-items: center;
width: 160px;
height: 25px;
font-size: 1rem;
font-family: ShortStack, sans-serif;
border-radius: 10px;
margin-top: -2px;
background: linear-gradient(180deg, #ffae19, #ff871a);
border: 1px solid #ff8c00;
color: #fff;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -4px 4px rgba(255, 255, 255, 0.25);
cursor: pointer;
transition: all 0.2s ease-in-out;
padding: 5px;
}

.raffleBtn:hover {
transform: translateY(-2px);
box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25), inset 0px -8px 8px rgba(255, 255, 255, 0.25);
}

.raffleBtn:active {
transform: translateY(0);
box-shadow: none;
}










