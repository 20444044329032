.nicknameBar {
  display: inline-block;
  width: 200px;
  height: 35px;
  border: 2px solid #B1F2B7;
  backdrop-filter: blur(2px);
  border-radius: 20px;
  opacity: 0.9;
  
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto 0;

  word-wrap: break-word;
  transition: ease-in-out all 0.3s; 
  position: relative;
  left: 10px;
  z-index: 0;
  background: rgba(0, 0, 0, 0.39);}


.nicknametext {

  color: rgba(255, 255, 240, 0.744);
  font-family: ShortStack;
  font-size: 1.3rem;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);

  padding: 4px;
  margin-left: 4px;

}


.nicknametextinput {
  color: rgba(255, 255, 240, 0.6);
  font-family: ShortStack;
  font-size: 1.3rem;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.43);

  padding: 4px;
  margin-left: 4px;
  width: 200px;
  height: 35px;
  border: 2px solid #B1F2B7;
  backdrop-filter: blur(2px);
  border-radius: 20px;
  opacity: 0.9;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto 0;

  word-wrap: break-word;
  transition: ease-in-out all 0.3s; 
  position: relative;
  left: 10px;
  top: 0px;
  z-index: 0;
  background: rgba(0, 0, 0, 0.39);
  caret-color: #B1F2B7;
}


.nicknametextinput:focus {
  border: 2px solid #B1F2B7;
  outline: 3px solid #B1F2B7;
}

::placeholder {
  color: rgba(255, 255, 240, 0.2);
  font-family: ShortStack;
  font-size: 1.1rem;
  text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.43);
  font-style: italic;
} 

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
  -webkit-text-fill-color: #333;
}



.nicknametextinput.wrong {
  border-color: #FF8A8A;
}

.nicknametextinput.wrong:focus,
.nicknametextinput.wrong:active {
  border-color: #FF8A8A;
  outline: 3px solid #FF8A8A;
}

.nicknametextinput.wrong::placeholder {
  color: #FF8A8A;
}

.nicknametextinput.wrong::selection {
  background: #FF8A8A;
  color: #fff;
}



