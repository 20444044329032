.horizontal-divider {
  position: relative;
  height: 1px;
  margin: 20px 0;
  background-color: rgba(255, 255, 255, 0.4);
}

.horizontal-divider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(76, 175, 80, 0.8);
  transition: width 3s ease-in-out;
  z-index: -1;
}

.horizontal-divider:hover:before {
  background-color: rgba(76, 175, 80, 0.8);
}

.horizontal-divider:active:before {
  width: 100%;
}

.horizontal-divider:active:before {
  animation: fill 1.5s ease-in-out forwards;
}

@keyframes fill {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
