.explorerToggle {
    position: relative;
    height: 100%;
    width: calc(0.7*200px);
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.215);
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)), linear-gradient(82.95deg, rgba(50, 59, 94, 0.792) 17.45%, rgba(29, 58, 61, 0.8) 98.97%);
    box-shadow: inset 4px 4px 5px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin-top: 6px;
    margin-right: 10px;
}


.notch {
    height: calc(0.7*90px);
    width: calc(0.7*90px);
    border-radius: 50%;
    background-color: #7df9e287;
    position: relative;
    top: 4px;
    left: 5px;
    box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.11), inset -6px 4px 6px #7df9e287, inset -6px -5px 3px rgba(0, 0, 0, 0.2);
}


.notch2 {
    height: calc(0.7*90px);
    width: calc(0.7*90px);
    border-radius: 50%;
    background-color: #D8846F;
    position: relative;
    top: 4px;
    left: calc(0.7*103px);
    box-shadow: 0 0 5px rgba(255, 228, 196, 0.531);    
    box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.11), inset -6px 4px 6px rgba(255, 156, 131, 0.91), inset -6px -5px 3px rgba(0, 0, 0, 0.2);
}



.accountImg {
    position: relative;
    left: -16px;
    top: -16px;
    width: calc(0.75*122px);
}

.explorerImg {
    position: relative;
    left: -15px;
    top: -25px;
    width: calc(0.75*130px);
}





.leaderboard-img {

position: relative;

width: 40%;

min-width: 250px;

animation:  drop-down-leaderboard 0.5s cubic-bezier(0.232, 1.000, 0.322, 1.000) both;

-webkit-animation: drop-down-leaderboard 0.5s cubic-bezier(0.232, 1.000, 0.322, 1.000) both;

animation-delay: 0.6s;


z-index: 1000;

}