




@font-face {
    font-family: ShortStack;
  
    src: local("ShortStack"),
   url("./ShortStack.ttf")
    
  }

  


.title {

    color: #b7ffc1df;
    text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.43);
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 40px;

    user-select: none;

}

.titleContainer2 {

    background: linear-gradient(90deg, rgba(0, 0, 0, 0.148) 2.21%, rgba(255, 252, 252, 0) 100%);
    width: 280px;
    height: 60px;
    text-align: center;
    align-items: center;
    display: flex;
    backdrop-filter: blur(5px);

    user-select: none;

}
