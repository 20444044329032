.searchField, .searchFieldPressed {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    width: 400px;
    height: 55px;
    outline: none;
    border: 20px;
    background: rgba(0, 0, 0, 0.14);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    backdrop-filter: blur(71px);
    border-radius: 17px;
    text-align: center;
}  




.searchField:hover:before, .searchFieldPressed:before
{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;

    background: linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%);
    opacity: 0.7;

    border-radius: 17px;

    transition: all 0.5s ease;

}


.searchField:hover:after 
    {
        content: '';
        position: absolute;
        inset: 2px;
    
        background: rgba(67, 90, 113, 1);
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    
        
        border-radius: 17px;

        transition: all 0.5s ease;


       
    }

    .searchField.focussed:after {

        background: rgba(67, 90, 113, 1);
       

        box-shadow: inset 5px 6px 4px rgba(0, 0, 0, 0.25);

        
    }


    .searchFieldPressed:after{
                content: '';
        position: absolute;
        inset: 2px;
    
        background: rgba(67, 90, 113, 1);
       

        box-shadow: inset 5px 6px 4px rgba(0, 0, 0, 0.25);
    
        
        border-radius: 17px;

        transition: all 0.5s ease;

    }







.searchField input {
  width: 106%;
  appearance: none; 
  height: 55px;
  position: relative;
  padding: 0 16px;
  border: none;
  border-radius: 17px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
   background:rgba(0, 0, 0, 0);
  color:rgba(125, 249, 226, 0.7);
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 200;

}



.searchFieldPressed input {
  width: 106%;
  height: 55px;
  position: relative;
  padding: 0 16px;
  border: none;
  border-radius: 17px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background: transparent;
  background-color: transparent;
  color:rgba(125, 249, 226, 0.7);
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 200;
}




.searchicon___btn {

    height: 90%;
    position: absolute;
    left: 3%;
    z-index: 100;
    float: left;
    padding: 5px;

}




.searchicon {

    height: 90%;
    position: absolute;
    left: 3%;
    z-index: 100;
    float: left;
    padding: 5px;

    animation: swipeRight 0.4s ease-out forwards;

}





@keyframes swipeRight {
  0% {left: 0; opacity: 1;}
  99% {left: 86%; opacity: 1;}
  100% {left: 86%; opacity: 0;}
}



@keyframes btnAppear {
  0% {opacity: 0;}
  99% {opacity: 0;}
  100% {opacity: 1;}
}



.searchField input::placeholder, .searchFieldPressed input::placeholder  { 
  z-index: 210;
  position: relative;
  color: bisque;
  font-size: 0.8rem;
  letter-spacing: 0.2em;
}

.searchField input:-ms-input-placeholder, .searchField input:-ms-input-placeholder { 
  z-index:  210;
  position: relative;
  color: bisque;
font-size: 0.8rem;
letter-spacing: 0.2em;

}

.searchField input::-ms-input-placeholder, .searchField input::-ms-input-placeholder { 
  z-index: 210;
  position: relative;
  color: bisque;
font-size: 0.8rem;
letter-spacing: 0.2em;
}











.searchFieldSmall, .searchFieldPressedSmall {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    width: 80vw;
    height: 55px;
    outline: none;
    border: 20px;
    background: rgba(0, 0, 0, 0.14);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    backdrop-filter: blur(71px);
    border-radius: 17px;
    text-align: center;

} 




.searchFieldSmall:hover:before, .searchFieldPressedSmall:before
{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;

    background: linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%);
    opacity: 0.7;

    border-radius: 17px;

    transition: all 0.5s ease;

}


.searchFieldSmall:hover:after 
    {
        content: '';
        position: absolute;
        inset: 2px;
    
        background: rgba(67, 90, 113, 1);
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    
        
        border-radius: 17px;

        transition: all 0.5s ease;


       
    }

    .searchFieldSmall .focussed:after {

        background: rgba(67, 90, 113, 1);
       

        box-shadow: inset 5px 6px 4px rgba(0, 0, 0, 0.25);

        
    }


    .searchFieldPressedSmall:after{
                content: '';
        position: absolute;
        inset: 2px;
    
        background: rgba(67, 90, 113, 1);
       

        box-shadow: inset 5px 6px 4px rgba(0, 0, 0, 0.25);
    
        
        border-radius: 17px;

        transition: all 0.5s ease;

    }









.searchFieldSmall input {
  width: 108%;
  appearance: none; 
  height: 55px;
  position: relative;
  padding: 0 16px;
  border: none;
  border-radius: 17px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
   background:rgba(0, 0, 0, 0);
  color:rgba(125, 249, 226, 0.7);
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 200;

}



.searchFieldPressedSmall input {
  width: 108%;
  height: 55px;
  position: relative;
  padding: 0 16px;
  border: none;
  border-radius: 17px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background: transparent;
  background-color: transparent;
  color:rgba(125, 249, 226, 0.7);
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 200;
}





.searchFieldSmall input::placeholder, .searchFieldPressedSmall input::placeholder  { 
  z-index: 210;
  position: relative;
  color: bisque;
  font-size: 12px;
  letter-spacing: 0.1em;

}

.searchFieldSmall input:-ms-input-placeholder, .searchFieldSmall input:-ms-input-placeholder { 
  z-index:  210;
  position: relative;
  color: bisque;
font-size: 12px;
letter-spacing: 0.1em;


}

.searchFieldSmall input::-ms-input-placeholder, .searchFieldSmall input::-ms-input-placeholder { 
  z-index: 210;
  position: relative;
  color: bisque;
font-size: 12px;
letter-spacing: 0.1em;

}
