
.App {
  text-align: center;
}

.App-header {
  background:
      linear-gradient(rgba(89, 225, 255, 0.12), rgba(89, 225, 255, 0.12)),
      radial-gradient(67.67% 67.67% at 50% 47.42%, rgba(155, 78, 124, 0.32) 0%, rgba(0, 212, 225, 0) 100%),
      radial-gradient(50% 50% at 50% 50%, rgba(253, 248, 199, 0.49) 0%, rgba(81, 172, 177, 0) 100%),
      linear-gradient(286.56deg, rgba(14, 14, 14, 0.6) 15.97%, rgba(99, 73, 133, 0.48) 80.9%),
      linear-gradient(180deg, rgba(52, 61, 63, 0.95) 0%, rgba(76, 90, 94, 0.95) 0.01%, rgba(20, 21, 21, 0.95) 100%),
      linear-gradient(180deg, rgba(139, 198, 236, 0.3) 0%, rgba(149, 153, 226, 0.2) 100%);
    
  background-blend-mode: soft-light;

  background-size: 100% 100%; 
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;


}



.App-header_2 {
  background:
      linear-gradient(rgba(89, 225, 255, 0.12), rgba(89, 225, 255, 0.12)),
      radial-gradient(67.67% 67.67% at 50% 47.42%, rgba(155, 78, 124, 0.32) 0%, rgba(0, 212, 225, 0) 100%),
      radial-gradient(50% 50% at 50% 50%, rgba(253, 248, 199, 0.49) 0%, rgba(81, 172, 177, 0) 100%),
      linear-gradient(286.56deg, rgba(14, 14, 14, 0.6) 15.97%, rgba(99, 73, 133, 0.48) 80.9%),
      linear-gradient(180deg, rgba(52, 61, 63, 0.95) 0%, rgba(76, 90, 94, 0.95) 0.01%, rgba(20, 21, 21, 0.95) 100%),
      linear-gradient(180deg, rgba(139, 198, 236, 0.3) 0%, rgba(149, 153, 226, 0.2) 100%);
    
  background-blend-mode: soft-light;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-header3 {
  background: red;
  overflow: hidden;

  height: 7500px;
  width: 7680px;
}

