@font-face {
    font-family: ShortStack;
  
    src: local("ShortStack"),
   url("./ShortStack.ttf")
    
  }



 .Card { 
    width: 140px;
    height: 140px;
    margin-right: 50px;
    border: 7px solid rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    margin-top: 33px;
    /*cursor: url(cursoractive.png),auto;*/
    transition: ease-in-out all 0.3s; 
  }


  .Card:hover, .Card.selected:hover  {
    border: 7px solid #B7FFC1;
    filter: drop-shadow(0px 0px 14px rgba(183, 255, 193, 0.56));
    border-radius: 18px;
  }


  .Card:active, .Card:focus, {
    border: 7px solid #B7FFC1;
    filter: drop-shadow(0px 0px 14px rgba(183, 255, 193, 0.56));
    border-radius: 18px;
  }


.Card.selected{
    border: 7px solid coral;
    filter: none;
    border-radius: 18px;
}



.Avatar {
  
  width: 140px;
  height: 140px;
  border-radius: 8px;
  opacity: 0.9;

  cursor: none;

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  
}



.Serial2 {
  position: relative;
  color: #fffffe45;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.43);
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
  margin-top: 5px;

  cursor: none;


  -webkit-user-select: none;
  -ms-user-select: none; 
  user-select: none; 
}



.Card.selected .Serial{
  color: coral;
  text-shadow: none;
}


.Card:hover .Serial{
  color: #B7FFC1;
}


.Serial {
  position: relative;
  color: #fffffe61;

  background-color: rgba(0, 0, 0, 0.17);

  border-radius: 7px;

  padding-top:2px;
  padding-bottom:2px;
  padding-left:4px;
  padding-right:4px;

  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.43);
  font-size: 1.1rem;
  width: 80%;
  text-align: center;
  margin-top: 5px;

  cursor: none;
  -webkit-user-select: none;
  -ms-user-select: none; 
  user-select: none; 
}



.serialId {

  color: #fffffe61;
  margin-right: 70px;
  text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.43);
  font-size: 1.1rem;
  background-color: rgba(0, 0, 0, 0.17);
  border-radius: 7px;
  padding-top:2px;
  padding-bottom:2px;
  padding-left:4px;
  padding-right:4px;

}





