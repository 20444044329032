.form-container {
  width: 60vw;
  height: 66vh;
  border-radius: 40px;
  display: flex;
  contain: content;
}


.form-container h1 {
  font-family: ButtonFont;
  color: var(--claycream-primary);
  letter-spacing: 0.13em;
  text-shadow: -3px 0px 0px rgba(0, 0, 0, 0.18);
  font-size: calc(10px + 3vmin);

  text-align: left;
  padding-left: 10%;
  margin-top: 7%;
  margin-bottom: 4%;
  opacity: 0.9;
}


.raffle-pannel h1 {
  font-family: ButtonFont;
  color: WHITE;
  letter-spacing: 0.13em;
  text-shadow: -3px 0px 0px rgba(0, 0, 0, 0.18);
  font-size: calc(10px + 3vmin);

  margin-top: 7%;
  margin-bottom: 4%;
  opacity: 1;

  text-align: center;
  justify-content: center;
  align-items: center;
}



.form-inputs {
  flex: 50%; 
  flex-direction: column;
  background: linear-gradient(30deg, rgba(23, 57, 85, 0.64) 0%, rgba(70, 113, 160, 0.64) 100%);
  opacity: 0.8; 
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  text-align: left;

 
}


.form-images {
  background-color: rgba(0, 0, 0, 0.819);
  height: 100%;
  width: 40%;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  contain: content;
 
}

.form-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.form-container input[type=text], select {
  width: 92%;
  max-width: 500px;
  background: rgba(0, 0, 0, 0.33);
  font-family: ShortStack;
  padding: 10px 20px;
  display: block;
  outline: 3px solid #01010170;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 4%;
  box-sizing: border-box;
  margin-top: 1%;
  font-size: calc(6px + 1vmin);
}

.form-container input[type=text]:focus {
  background: rgba(0, 0, 0, 0.5);
  outline:  3px solid var(--clayblue-primary);
}



.form-container label {
  font-family: ButtonFont;
  letter-spacing: 0.13em;
  text-shadow: -3px 0px 0px rgba(0, 0, 0, 0.18);
  font-size: calc(10px + 1vmin);
  opacity: 0.8;

}

.winner-form {
  text-align: left;
  padding-left: 10%;

}

.address-form {
  text-align: left;
  padding-left: 10%;
  display: flex;
  flex-direction: row;
}

.address-form input[type=text] {
  width:82%;
  background: rgba(0, 0, 0, 0.33);
  font-family: ShortStack;
  padding: 10px 20px;
  margin: 0;
  display: block;
  outline: 3px solid #01010170;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 5%;
  margin-top: 4%;
  margin-right: -1em;
}

.address-form  input[type=text]:focus {
  background: rgba(0, 0, 0, 0.5);
  outline:  3px solid var(--clayblue-primary);
}


.form-container input[type=submit] {
  font-family: ButtonFont;
  letter-spacing: 0.13em;
  font-size: calc(10px + 1.5vmin);
  border-radius: 10px;
  text-shadow: -3px 0px 0px rgba(0, 0, 0, 0.18);
  background: rgba(0, 0, 0, 0.30);
  outline:  3px solid var(--clayblue-primary);
  border: none;
  color: white;
  padding: 16px 32px;
  cursor: pointer;
  margin-left: 10%;
  margin-top: 4%;
}

.form-container input[type=submit]:hover {
  background-color: var(--clayblue-primary);
  transition: 1s ease-in-out;
}
