   
   .GC-flip-card_2 {
         background-color: transparent;
        width: 330px;
        height: 460px;
        perspective: 1000px;
        border-radius: 20px;
        text-align: center;

    }
    
    .GC-flip-card-inner_2 {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;


        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);


        width: 226px;
        height: 320px;

    



        cursor: pointer;

        border-radius: 10px;


        box-shadow: 0 0 22px 7px rgba(255, 181, 181, 0.3); 
        

    }
    
    .GC-flip-card_2:hover .GC-flip-card-inner_2 {
        transform: rotateY(180deg);
    }
    



    .GC-flip-card-front_2, .GC-flip-card-back_2 {
       position: absolute;
    
        text-align: center;

        border-radius: 10px;

        width: 226px;
        height: 320px;


        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    
    
    .GC-flip-card-front_2 {
    
      
        color: black;
        display: block;

        background-color: transparent;

        top: -35px;
        left: -35px;


        text-align: center;


    

    
    

    }
    
    .GC-flip-card-back_2 {
        background-color:transparent;
        color: white;


        border-radius: 10px;

        box-shadow: 0 0 22px 7px #53caee9f; 


        border: 3px solid #53C9EE;

        transform: rotateY(180deg);

        width: 226px;
        height: 320px;  

        text-align: center;


    }



    .GC-clay-nft_2 {

position: relative;


    display: block;


    width: 226px;
    height: 226px;

    top: -500px;
    left: 27px;

    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    
    }


    .GC-clay-nft_2 img{

        display:block;

    }



    .GC-clay-id_2 {

position: absolute;

    top: 390px;
    left: 158px;

    font-family: ShortStack;

    font-size: 1.2rem;

    color:#AEE8FB;

    opacity:0.9;
  

}



.GC-collection-back_2 {


    position: absolute;

    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0px;

    padding-top:1%;


    color:#AEE8FB;
    
    font-size: 1.1rem;
    font-family:ShortStack; 


}





.GC-clay-id-back_2 {


    position: absolute;

    left: 0;
    right: 0;
    margin: 0 auto;

    top: 0px;

    padding-top:9%;


    color:#AEE8FB;
    
    font-size: 1rem;
    font-family:ShortStack; 

      }



      .GC-clay-nft-back_2 {

position: absolute;


left: 0;
    right: 0;
    margin: 0 auto;


    top: 0px;

    padding-top:18%;


width: 25%;


      }





      .GC-clay-properties_2 {

        align-items: left;
        
        
        font-size: 0.5em;
        
        
        display: flex; 
        justify-content: center; 
        text-align: left;


        padding-top:1%;
        
        line-height: 1;


        position: relative;

        left: 0;
        right: 0;
        margin: 0 auto;

    
    
   
        
          }
        
        
          .GC-clay-percentage_2 {
        
            font-size: 0.65em;
        display: flex;
        
        justify-content: center;
        text-align:right;

     
        
        color: #AEE8FB;
        line-height: 1.2;


        position: relative;

        left: 0;
        right: 0;
        margin: 0 auto;

        
          }


          .GC-properties_2 {


            position: relative;

            left: 0;
            right: 0;
            margin: 0 auto; 
            
            top: -5px;

          }
        
        

    
        

          .GC-price-tag-back_2 {

            background-color: transparent;
            
            border: 1px solid transparent ;
            
            width: 134px;

            text-align: center;
            
            letter-spacing: 0.2;
            
            border-radius: 10px;
            
                        position: absolute;
                        
                        left: -10px;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: -30%;
                        
                    
            
            
                        font-size: 1.1rem;
            
            font-family:ShortStack; 
                        
                        
            
            padding: 3px;
            
            
                        display: inline-flex;
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        position: relative;
                    
                        box-sizing: border-box;
                        -webkit-tap-highlight-color: transparent;
                    
                    
                        outline: none;
                    
            
                    
            
            
                       cursor: pointer;
                       
                        
                          }


                          .GC-pricetag_2 {


                            position: absolute;

                            left: 0;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 0px;
                        
                            width: 100%;

                            display:block;

                          }


                          .GC-ADA-front_2 {


                    

                            position: absolute;

                            left: -2%;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 98%;   

                            width: 10%;

                          }

            


                          .GC-ADA-back_2 {


                            z-index: 1;


                            position: relative;

                            left: -12%;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 22px;   

                            width: 17%;

                          }




                          .GC-amount_2 {

                            position: absolute; 

                            left: 33%;  
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 98%;


                            font-size: 1.1rem;
            
                            font-family:ShortStack; 

                            opacity: 0.9 ;

                          }


                          .GC-amount-back_2 {


                            color: black;
                            position: absolute; 

                            left: 35%;  
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 90%;

                            opacity: 0.9 ;  

                            z-index: 1;
                          }
            
            

                          .GC-price-back_2 {

                            position: absolute; 

                            left: 0;
                            right: 0;
                            margin: 0 auto; 

                            top: 81%;
                            
                          }
                          


                          .GC-tokens_2 {

                            position: absolute; 
                            width: 200px;
                            left: 105px;
                            top: 440px;

                            color: white;
                            opacity: 0.9 ;

                            font-size: 1.2rem;
            
                            font-family:ShortStack; 


                          }



                          .GC-price-tag-back_2:hover .GC-pricetag_2  {

                            display:none;
                           

                          }


                          .GC-price-tag-back_2:hover .GC-ADA-back_2  {
                            display:none;
                            cursor: pointer;

                          }


                          .GC-price-tag-back_2:hover span {
                            display:none

                          }
                          
                          .GC-price-tag-back_2:hover:before {
                            content:"BUY NFT";

                            font-size: 1rem;
                            font-family:ShortStack; 
                         
                      

                          }
                          
                          .GC-price-tag-back_2:hover {


                            background-color: rgba(0, 0, 0, 0.688);


                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            border: 1px solid black;
                            color: white;

                        
                            padding: 4px;

                            font-family:ShortStack; 


                            position: relative;
                        
                           left: 0;
                            right: 0;
                            margin: 0 auto; 
                            
                            top: 22px;
                        
                    
                            width: 50%;


                          }
      